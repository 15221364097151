import React, { useState, useEffect } from 'react';

import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';

import { useSelector, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';

import { fetchIntegrations } from 'src/module/integration/action';
import { getIntegrations } from 'src/module/integration/selector';
import { fetchControlPanelTags, clearControlPanelTags, setSettingsModalClose, fetchCommLoopTags } from 'src/module/device/action';
import { getControlPanelTags, getSettingsModal, getCommLoopTags, getShowSynchronizingDevicesPopUp } from 'src/module/device/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { addController } from 'src/module/controller/action';
import InitDeviceModel from 'src/screen/Protected/DeviceScreen/InitDeviceModel';
import { getFeatures } from 'src/module/authentication/selector';

export default function AddControllerModal () {
  const dispatch = useDispatch();

  const integrations = useSelector(getIntegrations);
  const commLoopTags = useSelector(getCommLoopTags);
  const settingsModal = useSelector(getSettingsModal(null));
  const [panelId, setPanelId] = useState(null);
  const [filledValuesObj, setFilledValuesObj] = useState({});
  const fieldValuesObj: any = {};
  const showSyncPopUp = useSelector(getShowSynchronizingDevicesPopUp);
  const features: any = useSelector(getFeatures);

  useEffect(() => {
    dispatch(clearControlPanelTags());
    dispatch(fetchIntegrations());
    dispatch(fetchCommLoopTags());
    dispatch(fetchControlPanelTags());
    dispatch(setSettingsModalClose(null));
  }, []);

  let frogName = null, frogId = null;
  const frogClients = [];
  integrations?.forEach(integration => {
    if (integration.get('type') === 'frog') {
      frogId = integration?.get('id');
      frogName = integration?.getIn(['settings', 'name']) || frogId.substring(0, 5);
      frogClients.push({ label: frogName, value: frogId });
    }
  });
  const commLoopOptions = [{ label: 'Add Comm Loop', value: 'null' }];
  commLoopTags?.forEach(commLoop => {
    commLoopOptions.push({ label: commLoop.get('tag'), value: commLoop.get('id') });
  });

  const onValueChange = (panel) => {
    setPanelId(panel.id);
  };

  const controllerOptions = [
    { label: 'NVent 920', value: 'NVent920' },
    { label: 'NVent NGC30', value: 'NVentNGC30Controller' },
    { label: 'NVent NGC40', value: 'NVentNGC40Controller' },
    { label: 'NVent AC2000+', value: 'NVentAC2000P' },
    { label: 'Thermon TCM2', value: 'ThermonTCM2Controller' },
    { label: 'Thermon TCM18', value: 'ThermonTCM18Controller' },
    { label: 'Thermon TC101', value: 'ThermonTC101Controller' },
    { label: 'Thermon TC201', value: 'ThermonTC201Controller' },
    { label: 'Thermon TC202', value: 'ThermonTC202Controller' },
    { label: 'Thermon TC1818', value: 'ThermonTC1818Controller' },

  ];

  if (features.includes('beta')) {
    controllerOptions.push({ label: 'ATCOM+', value: 'ATCOMPlusController' });
    controllerOptions.push({ label: 'Thermon Genesis', value: 'ThermonGenesisController' });
    controllerOptions.push({ label: 'MasterTrace MR100', value: 'MasterTraceMR100Controller' });
    controllerOptions.push({ label: 'MSCP Modular EHT Controller', value: 'MSCPController' });
    controllerOptions.push({ label: 'SmartTrace Controller', value: 'SmartTraceController' });
    controllerOptions.push({ label: 'SmartTrace Monitored Panel', value: 'SmartTraceMPController' });
  }

  const commonControllerFields = {
    device_factory: {
      type: 'select',
      label: 'Device Type',
      options: controllerOptions,
      errorText: 'Required',
      binding: true,
      order: 10
    },
    parent_id: {
      type: 'select',
      label: 'Comm Loop',
      options: commLoopOptions,
      errorText: 'Required',
      order: 30
    },
    tag: {
      type: 'text',
      label: 'Controller Tag',
      errorText: 'Required',
      order: 70
    },
    address: {
      type: 'number',
      label: 'Controller address',
      errorText: 'Required',
      order: 80
    }
  };

  const [addControllerFields, setAddControllerFields] = useState(commonControllerFields);

  const handleChange = (name, value) => {
    fieldValuesObj[name] = value;
    if (name === 'parent_id' && value === 'null') {
      setAddControllerFields(Object.assign({}, addControllerFields, {
        frog_client_id: {
          type: 'select',
          label: 'Assigned Supervisor Client',
          options: frogClients,
          errorText: 'Required',
          order: 40
        },
        ip_address: {
          type: 'text',
          label: 'IP address',
          errorText: 'Required',
          order: 50
        },
        port: {
          type: 'number',
          label: 'Port',
          errorText: 'Required',
          order: 60
        },
      }));
    }
    else if (name === 'parent_id' && value !== 'null') {
      delete addControllerFields['ip_address'];
      delete addControllerFields['port'];
      delete addControllerFields['frog_client_id'];
      delete filledValuesObj['ip_address'];
      delete filledValuesObj['port'];
      delete filledValuesObj['frog_client_id'];
      setAddControllerFields(Object.assign({}, addControllerFields, {}));
    }
    if (name === 'device_factory' && value === 'NVent920') {
      (addControllerFields as any).subaddress = {
        type: 'number',
        label: 'Subaddress',
        errorText: 'Required',
        order: 90
      };
      setAddControllerFields(addControllerFields);
    }
    else if (name === 'device_factory') {
      delete addControllerFields['subaddress'];
    }

    if (name === 'device_factory' && value === 'NVentNGC40Controller') {
      delete addControllerFields['control_panel_id'];
    }
    else if (name === 'device_factory' && value !== 'NVentNGC40Controller') {
      (addControllerFields as any).control_panel_id = {
        type: 'autoCompleteSelect',
        label: 'Panel',
        options: [],
        deviceType: 'panel',
        optionsSelector: getControlPanelTags,
        freeSolo: true,
        formatOption: (option) => ({
          label: option.get('tag'),
          value: option.get('id')
        }),
        onValueChange,
        order: 20,
      };

      setAddControllerFields(addControllerFields);
    }
    setFilledValuesObj(Object.assign({}, filledValuesObj, fieldValuesObj));
  };

  useEffect(() => {
    if (!settingsModal?.get('isOpen')) {
      setAddControllerFields(commonControllerFields);
      setFilledValuesObj({});
    } else {
      dispatch(fetchControlPanelTags());
    }
  }, [settingsModal?.get('isOpen')]);

  const handleClose = () => {
    setAddControllerFields(commonControllerFields);
    setFilledValuesObj({});
    dispatch(setSettingsModalClose(null));
  };

  const addControllerModalSubmit = (rec: any) => {
    const address = parseInt(rec.get('address'));
    let subaddress = rec.get('subaddress', null);
    if (subaddress) {
      subaddress = parseInt(rec.get('subaddress'));
    }
    let port = rec.get('port', null);
    if (port) {
      port = parseInt(port);
    }
    const parentId = rec.get('parent_id') === 'null' ? null : rec.get('parent_id');
    rec = rec.set('address', address);
    rec = rec.set('subaddress', subaddress);
    rec = rec.set('port', port);
    if (panelId?.includes('new-user-entry~')) {
      rec = rec.set('new_control_panel', panelId.split('~')[1]);
      rec = rec.delete('control_panel_id');

    } else {
      rec = rec.set('control_panel_id', panelId);
    }
    rec = rec.set('parent_id', parentId);
    dispatch(addController(rec));
  };
  return (
    <>
      {
        showSyncPopUp ?
          <InitDeviceModel />
          :
          null
      }

      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.ADD_CONTROLLER}
        handleClose={handleClose}
        handleSubmit={addControllerModalSubmit}
        handleChange={handleChange}
        title={'Add New Controller'}
        loading={settingsModal?.get('loading')}
        settings={fromJS(filledValuesObj)}
        fields={
          fromJS(addControllerFields)
        }
      />
    </>


  );
}

AddControllerModal.propTypes = {
};

AddControllerModal.defaultProps = {
};
