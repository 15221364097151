import './CircuitsList.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { List, Map } from 'immutable';
import DataTable from 'src/component/UI/DataTable';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Badge } from '@mui/material';
import variables from 'src/style/variable/variables.module.scss';

import { getCircuits, getLoadingCircuits, getNumCircuitPages, getTotalCircuits } from 'src/module/circuit/selector';
import { fetchCircuits, fetchCircuitsExport } from 'src/module/circuit/action';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeviceLink from 'src/component/UI/DeviceLink';
import { formatAlarmLabel } from 'src/component/AlarmsList';
import { getAlarmSummary } from 'src/module/device/selector';
import Tooltip from '@mui/material/Tooltip';
import Button from 'src/component/UI/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getSingularWorkTicketLabel, getTablePagePreferences } from 'src/module/authentication/selector';
import { langLookUpValidationError } from 'src/utils/langLookUp';


export const alarmsByStatusLegendLabels = Map({
  'low temp above 5': { label: 'Low Temp Above 5° C' },
  'low temp below 5': { label: 'Low Temp Below 5° C' },
  'low temp above 0': { label: 'Low Temp Above 0° C' },
  'comm fail': { label: 'Comm Fail' },
  'low load': { label: `Low Load` },
  'hi load': { label: `High Load` },
  'rtd fail': { label: `RTD Fail` },
  'gfi': { label: 'GFI' },
  'controller comm fail': { label: `Controller Comm Fail` },
  'switch fail': { label: `Switch Fail` },
  'low temp below 0': { label: `Low Temp Below 0° C` },
  'reset': { label: `Reset` },
  'gf ct failure': { label: `GF CT Failure` },
  'other': { label: `Other` }
});

export default function CircuitsList (props) {
  const {
    status,
    noAlarmsHasNotificationFilterOption,
    controllerIdOverride,
    panelId,
    batchId,
    circuitId,
    showUnit,
    showAlarms,
    showModel,
    showErrors,
    hideCircuit,
    hideController,
    showCombinedCircuitAndController,
    multiSelect,
    selected,
    updateSelected,
    isAllSelectedOnPage,
    isAllSelected,
    onSelectAllOnPage,
    onSelectAll,
    onUpdateFilter,
    highlightedDevices,
    filterable,
    banner,
    allowRemove,
    onRemoveRow,
    allowDownload,
    deviceErrors
  } = props;

  const selectedFilters = useSelector(getDevicesSelectedFilters);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);
  const userPagePreference = useSelector(getTablePagePreferences('circuitsTable'));
  const curCircuits: any = useSelector(getCircuits) || List();
  const circuits: any = curCircuits || List();
  const loading: any = useSelector(getLoadingCircuits);
  const totalCircuits: any = useSelector(getTotalCircuits);
  const [page, setPage] = useState(0);
  const [dataTableUpdateKey, setDataTableUpdateKey] = useState(0);
  const [pageSize, setPageSize] = useState(userPagePreference);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [noAlarmHasNotification, setNoAlarmHasNotification] = useState(false);
  const numPages: any = useSelector(getNumCircuitPages);
  const dispatch: any = useDispatch();

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    onUpdateFilter(_filter);
    setSort(_sort);
  };

  // forces the memoized datatable to re-render so it shows the correct error state
  useEffect(() => {
    setDataTableUpdateKey(dataTableUpdateKey + 1);
  }, [highlightedDevices, deviceErrors]);



  useEffect(() => {
    if (isAllSelected && circuits && circuits.size) {
      const newSelected = circuits.map((c) => c.get('id')).toJS();
      const mergedArray = [...new Set([...selected, ...newSelected])];
      updateSelected(mergedArray);
    }
  }, [circuits, page, isAllSelected]);
  const ref_filters = useRef(null);
  const ref_others = useRef(null);
  useEffect(() => {
    const others = `${controllerIdOverride} ${circuitId} ${panelId} ${page} ${filter} ${sort} ${pageSize} ${batchId}`;
    if (!_.isEqual(ref_filters.current, selectedFilters.toJS()) || others !== ref_others.current) {
      ref_filters.current = selectedFilters.toJS();
      ref_others.current = others;
      const optionalFilters = {
        controllerId: controllerIdOverride,
        panelId,
        circuitId,
        batchId,
        userSearchInput: filter,
        status: status || null,
        sort,
        noAlarmHasNotification,
        withAlarmSummary: true
      };
      dispatch(fetchCircuits(selectedFilters, Map(optionalFilters), page, pageSize));
    }
  }, [selectedFilters, controllerIdOverride, circuitId, panelId, page, filter, sort, pageSize, batchId]);


  const handleUpdateFilter = () => {
    const optionalFilters = {
      controllerId: controllerIdOverride, panelId, circuitId, batchId, userSearchInput: filter, status: status || null,
      noAlarmHasNotification: !noAlarmHasNotification
    };
    dispatch(fetchCircuits(selectedFilters, Map(optionalFilters), page, pageSize));
    setNoAlarmHasNotification(!noAlarmHasNotification);
  };

  let title = 'Circuits';

  if (status === 'offline') {
    title = 'Offline Circuits';
  }

  const alarm = (device_id) => {
    const alarms: any = useSelector(getAlarmSummary(device_id));
    const numAlarms = (alarms?.match(/,/g) || []).length + 1;
    if (alarms === "") {
      return "";
    }
    if (numAlarms < 3) {
      return formatAlarmLabel(alarms);
    }
    //  icon with badge (#) with the amount of alarms
    return (
      <Tooltip title={`${formatAlarmLabel(alarms)}`} className='alarm' data-testid='device-link'>
        <div className='alarm-badge'>
          <Badge badgeContent={numAlarms} color="primary" className='alarmBadge'>
            <NotificationsActiveIcon fontSize='large' className='bell' />
          </Badge>
        </div>
      </Tooltip>
    );
  };

  const handleClick = () => {
    dispatch(fetchCircuitsExport(selectedFilters, Map({ panelId, controllerId: controllerIdOverride })));
  };

  const isSelected = (id) => {
    return selected.some(selectedId => selectedId == id);
  };

  const handleSelectAllOnPageClick = () => {
    if (!isAllSelectedOnPage) {
      const newSelected = circuits.map((c) => c.get('id')).toJS();
      updateSelected(newSelected);
      onSelectAllOnPage(true);
      return;
    }
    onSelectAllOnPage(false);
    onSelectAll(false);
    updateSelected([]);
  };

  const handleSelectAllClick = () => {
    if (isAllSelected) {
      onSelectAll(false);
      onSelectAllOnPage(false);
      updateSelected([]);
      return;
    }
    onSelectAll(true);
  };

  const handleClickCheck = (event: React.MouseEvent<unknown>, id: number) => {
    const isSelected = selected.find((e) => e == id) !== undefined;
    let newSelected = isSelected
      ? selected.filter(item => item != id)
      : [...selected, id];

    // if the user is manually unchecking an item after selecting all, then we'll unselect everything except the items on the page
    if (isAllSelected) {
      const circuitsOnPage = circuits.map((c) => c.get('id')).toJS();
      newSelected = selected.filter(item => circuitsOnPage.includes(item) && item != id);
    }
    updateSelected(newSelected);
    onSelectAllOnPage(false);
    onSelectAll(false);
  };


  const getClass = (rec) => {
    if (rec && rec.getIn && deviceErrors) {
      if ( deviceErrors?.getIn([rec.get('id'), 'status', 'hasErrors'])) {
        return 'row-error';
      }
      if ( deviceErrors?.getIn([rec.get('id'), 'status', 'isWarning'])) {
        return 'row-error-warn';
      }
    }

    return '';
  };

  const iconStyles = { color: variables.primarySolid };

  const getControllerTag = (value) => {
    return value.get('parent_type') === 'commLoop' ? null : value.get('parent_tag');
  };

  const handleRemoveRow = (id) => {
    onRemoveRow(id);
  };

  const dataColumns = [];
  if (multiSelect) {
    dataColumns.push({
      id: 'select-column',
      Header: () => <Checkbox
        color="primary"
        checked={isAllSelectedOnPage || isAllSelected}
        onChange={handleSelectAllOnPageClick}
      />,
      accessor: (row: any) => row.id,
      sortable: false,
      isSorted: false,
      disableSortBy: true,
      ClassName: (row: any) => {
        const id = row.original.get('id');
        return getClass(id);
      },
      Cell: (row: any) => {
        const isCheckSelected = isSelected(row.cell.row.original.get('id'));
        return (
          <Checkbox
            checked={isCheckSelected}
            onClick={(event) => handleClickCheck(event, row.cell.row.original.get('id'))}
            color="primary"
          />
        );
      }
    });
  }

  if (!hideController) {
    dataColumns.push({
      id: 'controller.tag',
      Header: 'Controller',
      accessor: (row: any) => {
        if (row && row.get) {
          return row?.get('parent_tag');
        }
        return 'N/A';
      },
      sortable: true,
      ClassName: (row: any) => {
        return getClass(row.original);
      },
      Cell: (row: any) => {
        return (
          <DeviceLink label={row.cell.value} controllerTag={getControllerTag(row.cell.row.original)} />
        );
      }
    });
  }
  if (!hideCircuit) {
    dataColumns.push({
      id: 'devices.tag',
      Header: 'Circuit',
      accessor: (row: any) => {
        if (row && row.get) {
          return row.get('tag');
        }
        return '';
      },
      ClassName: (row: any) => {
        return getClass(row.original);
      },
      sortable: true,
      Cell: (row: any) => {
        return (
          <DeviceLink label={row.cell.value} circuitTag={row.cell.value} rawTag={row.cell.row.original.get('raw_tag')} controllerTag={getControllerTag(row.cell.row.original)} />
        );
      }
    });
  }
  if (showCombinedCircuitAndController) {
    dataColumns.push({
      id: 'controller_tag',
      Header: 'Controller / Circuit',
      accessor: (row: any) => row?.get('tag'),
      maxWidth: '200px',
      Cell: (row: any) => {
        return (
          <>
            <DeviceLink label={row.cell.value} controllerTag={row.cell.row.original.get('parent_tag')} /> /
            {row.cell.value ? (<DeviceLink label={row.cell.value} circuitTag={row.cell.value} controllerTag={getControllerTag(row.cell.row.original)} rawTag={row.cell.row.original.get('raw_tag')} />) : 'N/A'}
          </>
        );
      },
      ClassName: (row: any) => {
        const classes = [];
        const id = row.original?.get('id');
        if (row.original.get('critical') === 1) {
          classes.push('critical');
        }
        if (getClass(id)) {
          classes.push(getClass(id));
        }
        return classes.join(' ');
      }
    });
  }

  if (showUnit) {
    dataColumns.push(
      {
        id: 'devices.unit',
        Header: 'Unit',
        accessor: (row: any) => row?.get('unit'),
        sortable: true,
        ClassName: (row: any) => {
          return getClass(row.original);
        },
        Cell: (row: any) => { return row.cell.value ? row.cell.value : ''; }
      },
    );
  }
  if (showAlarms) {
    dataColumns.push(
      {
        id: 'devices.alarms',
        Header: 'Alarms',
        accessor: (row: any) => row?.get('id'),
        disableSortBy: true,
        ClassName: (row: any) => {
          return getClass(row.original);
        },
        Cell: (row: any) => { return alarm(row.cell.value) || ''; }
      },
    );
  }
  if (showModel) {
    dataColumns.push(
      {
        id: 'devices.model',
        Header: 'Model',
        accessor: (row: any) => row?.get('model'),
        sortable: true,
        ClassName: (row: any) => {
          return getClass(row.original);
        },
        Cell: (row: any) => { return row.cell.value ? row.cell.value : ''; }
      }
    );
  }
  if (showErrors) {
    dataColumns.push(
      {
        id: 'devices.errors',
        Header: 'Error',
        sortable: false,
        ClassName: (row: any) => {
          return getClass(row.original);
        },
        Cell: (row: any) => {
          const errs =  deviceErrors?.getIn([row?.cell?.row?.original?.get("id"), 'status', 'errors']);
          if (List.isList(errs)) {
            return errs.map(err => langLookUpValidationError(err)).toArray();
          }
          return errs;

        }
      }
    );
  }

  if (allowRemove) {
    dataColumns.push(
      {
        id: 'remove_all',
        disableSortBy: true,
        ClassName: (row: any) => {
          return getClass(row.original);
        },
        accessor: (row: any) => {
          return <div className='error-actions'><Button onClick={() => { handleRemoveRow(row?.get('id')); }} icon={<RemoveCircleOutlineIcon style={{ fontSize: 14 }} />} /></div>;
        },
      },
    );
  }
  return (
    <div className='circuits-list' data-testid='circuits-list'>
      <h3>{title}{allowDownload ? <Button onClick={() => handleClick()} icon={<FileDownloadOutlinedIcon style={iconStyles} />}></Button> : null}</h3>
      <DataTable
        filterable={filterable}
        bannerComponent={banner ? <div
          className={'data-table-banner'}
        >
          <div className='data-table-inner'>
            <div>{banner.replace('%num_circuits%', isAllSelected ? totalCircuits : selected.length)}</div>
            <span className='data-table-pipe' >|</span>
            {
              !filter ?
                <div className="clickable-text" onClick={handleSelectAllClick}>
                  {isAllSelected ? 'Clear Selection' : 'Select All'}
                </div> : null
            }

          </div>
        </div> : null}
        filterLabel='Search Circuits'
        columns={dataColumns}
        data={circuits}
        unit={selectedFilters.get('unit')}
        pagination
        numPages={numPages}
        totalRecords={totalCircuits}
        loading={loading}
        fetchPage={fetchPage}
        tableId='circuitsTable'
        columnWidths={['10px', 'auto', 'auto', 'auto', 'auto', 'auto']}
        key={dataTableUpdateKey}
      />

      {(noAlarmsHasNotificationFilterOption) ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={noAlarmHasNotification}
              onChange={handleUpdateFilter}
              name="noAlarmCheck"
              color="primary"
            />
          }
          label={`Circuits with a ${singularWorkTicketLabel} but No Alarms`}
        />) : null}

    </div>
  );
}

CircuitsList.propTypes = {
  pagination: PropTypes.bool,
  includeArchived: PropTypes.bool,
  showSetting: PropTypes.bool,
  showValue: PropTypes.bool,
  showUnit: PropTypes.bool,
  showModel: PropTypes.bool,
  showAlarms: PropTypes.bool,
  showAlarmRaisedAt: PropTypes.bool,
  showErrors: PropTypes.bool,
  hideCircuit: PropTypes.bool,
  hideController: PropTypes.bool,
  showCombinedCircuitAndController: PropTypes.bool,
  suppressHeader: PropTypes.bool,
  suppressSearch: PropTypes.bool,
  noAlarmsHasNotificationFilterOption: PropTypes.bool,
  status: PropTypes.string,
  controllerIdOverride: PropTypes.string,
  panelId: PropTypes.string,
  batchId: PropTypes.string,
  circuitId: PropTypes.string,
  multiSelect: PropTypes.bool,
  updateSelected: PropTypes.func,
  onSelectAllOnPage: PropTypes.func,
  onSelectAll: PropTypes.func,
  onUpdateFilter: PropTypes.func,
  onRemoveRow: PropTypes.func,
  selected: PropTypes.array,
  isAllSelectedOnPage: PropTypes.bool,
  isAllSelected: PropTypes.bool,
  filterable: PropTypes.bool,
  allowRemove: PropTypes.bool,
  allowDownload: PropTypes.bool,
  highlightedDevices: PropTypes.object,
  banner: PropTypes.string,
  deviceErrors: PropTypes.object,
};

CircuitsList.defaultProps = {
  pagination: false,
  includeArchived: false,
  showSetting: false,
  showValue: false,
  showUnit: false,
  showAlarms: false,
  showModel: false,
  showAlarmRaisedAt: false,
  showErrors: false,
  hideCircuit: false,
  hideController: false,
  showCombinedCircuitAndController: false,
  suppressHeader: false,
  suppressSearch: false,
  multiSelect: false,
  isAllSelected: false,
  allowRemove: false,
  filterable: true,
  allowDownload: true,
  selected: [],
  highlightedDevices: List(),
  deviceErrors: Map(),
  updateSelected: () => { },
  onSelectAllOnPage: () => { },
  onRemoveRow: () => { },
  onSelectAll: () => { },
  onUpdateFilter: () => { }
};
