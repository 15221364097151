import './DashboardScreen.scss';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Helmet } from 'react-helmet';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import { getAlarmStats } from 'src/module/alarm/selector';
import { getUnits, getDevicesSelectedFilters } from 'src/module/filter/selector';
import { fetchAlarmStats, fetchAlarmHistoricalSummary, fetchDevicesInAlarmWithoutNotification } from 'src/module/alarm/action';
import { fetchMetrics } from 'src/module/facility/action';
import { fetchDashboardStats } from 'src/module/device/action';

import { getAuthenticatedUser, getClientId } from 'src/module/authentication/selector';
import { fetchNewWorkTickets } from 'src/module/workTicket/action';


import ResponsiveToggleGroup from 'src/component/UI/ResponsiveToggleGroup';
import DashboardStatistics from 'src/component/UI/Statistics/DashboardStatistics';
import { updateUser } from 'src/module/user/action';
import { isWhitelabel } from 'src/utils/utils';

export default function DashboardScreen () {
  const dispatch = useDispatch();

  const selectedFilters = useSelector(getDevicesSelectedFilters);
  const units = useSelector(getUnits);
  const clientId = useSelector(getClientId);
  const alarmStats = useSelector(getAlarmStats);
  const headerFilters = useSelector(getDevicesSelectedFilters);
  const user = useSelector(getAuthenticatedUser);
  const unverifiedContact = user.get('unverified_contact_number');
  useEffect(() => {
    dispatch(fetchDashboardStats(selectedFilters));
    dispatch(fetchAlarmStats(selectedFilters));
    dispatch(fetchMetrics());
    dispatch(fetchNewWorkTickets(moment().subtract('7', 'days').format('YYYY-MM-DD'), headerFilters, true));
    dispatch(fetchAlarmHistoricalSummary(selectedFilters, moment().add(-12, 'weeks').format('YYYY-MM-DD')));
    dispatch(fetchDevicesInAlarmWithoutNotification(selectedFilters));
  }, [clientId, selectedFilters]);

  useEffect(() => {
    if (unverifiedContact && unverifiedContact !== user?.get('contact_number')) {
      dispatch(updateUser({ contact_number: unverifiedContact, unverified_contact_number: null }));
    }
  }, [unverifiedContact]);

  const freshnessDate = alarmStats.getIn(['dataFreshness', 'overall']);
  const freshnessNote = (freshnessDate) ? moment(freshnessDate).format('YYYY-MM-DD hh:mm A') : 'N/A';
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div id='dashboard' className='page'>
      <Helmet>
        <title>{title} - Dashboard</title>
      </Helmet>

      <Grid container>
        <Grid item xs={12} md={12}>
          <h3>EHT System Overview by Unit <span className='freshness'>(Last Updated at {freshnessNote})</span></h3>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper>
            <ResponsiveToggleGroup
              buttons={units}
              mainLabel='All'
            />
          </Paper>
        </Grid>
      </Grid>
      <div className='innerElements'>
        <DashboardStatistics />
      </div>
    </div>
  );
}
