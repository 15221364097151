import PropTypes from 'prop-types';

import { getFeatures } from 'src/module/authentication/selector';
import { useSelector } from 'react-redux';

export default function FeatureFence (props) {
  const {
    can,
    noPermissionComponent,
    children
  } = props;

  const features: any = useSelector(getFeatures);
  const hasAllFeatures = can.every(required => features.includes(required));
  return hasAllFeatures ? children : (noPermissionComponent) ? noPermissionComponent : null;
}

FeatureFence.propTypes = {
  can: PropTypes.array.isRequired,
  noPermissionComponent: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

FeatureFence.defaultProps = {
};
