import './RulesScreen.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Condition from './Condition';
import Result from './Result';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AddIcon from '@mui/icons-material/Add';
import Button from 'src/component/UI/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { addRuleCondition, deleteRule } from 'src/module/rule/action';
import { getCriticalityLabels } from 'src/module/authentication/selector';
import { comparingText, conditionOptions as defaultConditionOptions } from 'src/utils/utils';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import PermissionFence from 'src/component/PermissionFence';
import PagerDutySvg from '../../../assets/icons/pager-duty.svg';
import { getDeviceGroupsLabels } from 'src/module/groupDevices/selector';

export default function Rule (props) {
  const {
    orderNumber,
    rule,
    hasPagerDutyIntegration
  } = props;
  const dispatch = useDispatch();

  const deviceGroupsLabels: any = useSelector(getDeviceGroupsLabels);
  const [conditions, setCondition] = React.useState(List([]));
  const [conditionTypeOptions, setconditionTypeOptions] = React.useState(List([]));
  const [pagerDutyNotificationDisabled, setPagerDutyNotificationDisabled] = React.useState(rule.get('criticality') === 0);

  const buildConditionOptions = () => {
    const updatedConditionsOptions: any = [... defaultConditionOptions];
    if (deviceGroupsLabels && deviceGroupsLabels.toJS) {
      const deviceGroupsOptions = deviceGroupsLabels.toJS().map((deviceGroupLabel) => {
        return {
          "value": deviceGroupLabel.id,
          "label": deviceGroupLabel.name,
        };
      });

      if (deviceGroupsOptions.length) {
        const groupedDevicesIndex = updatedConditionsOptions.findIndex(
          (option) => option.label === 'Device Group'
        );
        if (groupedDevicesIndex === -1) {
          const newGroupedDevicesOption = {
            "type": "select",
            "value": "device_groups",
            "conditionType": "eht_alarm",
            label: 'Device Group',
            "compareOptions": [
              "=",
            ],
            options: deviceGroupsOptions,
            "disabled": false
          };
          updatedConditionsOptions.push(newGroupedDevicesOption);
        }
      }
    }
    return updatedConditionsOptions;
  };
  const conditionOptions = buildConditionOptions();


  const loadConditions = () => {
    const conditionsTemp = [];
    const circuitConditionValues = Object.values(rule.getIn(['ruleConditions', 'eht_circuit'], Map()).toJS());
    const circuitRowsKeys = Object.keys(rule.getIn(['ruleConditions', 'eht_circuit'], Map()).toJS());

    const alarmConditionValues = Object.values(rule.getIn(['ruleConditions', 'eht_alarm'], Map()).toJS());
    const alarmRowsKeys = Object.keys(rule.getIn(['ruleConditions', 'eht_alarm'], Map()).toJS());

    circuitConditionValues.map((con: any, index) => {
      conditionsTemp.push({
        ...con,
        label: circuitRowsKeys[index],
      });
    });
    alarmConditionValues.map((con: any, index) => {
      conditionsTemp.push({
        ...con,
        label: alarmRowsKeys[index],
      });
    });
    setCondition(List(conditionsTemp.sort((a, b) => a.created_at > b.created_at ? 1 : -1)));
  };
  useEffect(() => {
    setPagerDutyNotificationDisabled(rule.get('criticality') === 0);
    loadConditions();
    const labels = conditions.map(x => x.label);
    const filteredOptions = conditionOptions.map((x: any) => {
      x.disabled = labels.includes(x.value);
      return x;
    });
    setconditionTypeOptions(List(filteredOptions));
  }, [rule, deviceGroupsLabels]);

  const addCondition = () => {
    const options = conditionOptions;
    const aConditionNotUsedYet = options.find(conOpt => !conditions.some(con => con.label === conOpt.value));
    if (aConditionNotUsedYet) {
      const addRule = {
        object: aConditionNotUsedYet.conditionType,
        property: aConditionNotUsedYet.value,
        value: ''
      };
      dispatch(addRuleCondition(rule.get('id'), Map(addRule)));

    }

    return;
  };
  const criticalityLabels = useSelector(getCriticalityLabels).toJS();
  const titleCreation = () => {
    if (conditions.size === 0) {
      return (<p className='generator'>
        {rule.get('alarm_group', 'None') ? 'Alarm group is' : ''}

        {rule.get('alarm_group', 'None') ? < b style={{ marginRight: '5px', marginLeft: '5px' }}>{rule.get('alarm_group', 'None')}</b> : ''}
        {rule.get('alarm_group', 'None') ? 'and ' : ''}
        criticality is
        <b style={{ marginRight: '5px', marginLeft: '5px' }}>{criticalityLabels[rule.get('criticality')]}</b>
      </p>
      );
    }
    const conditionsText = conditions.map((condition, index) => {
      const conditionInfo = conditionOptions.find(con => con.value === condition.label);
      const value = conditionInfo?.type === 'select' ? conditionInfo.options.find(x => x.value === condition.value)?.label : condition.value;
      const label = conditionInfo?.label;

      const compare = comparingText[condition.comparison];
      return (
        <em key={index} className='generatorInner'>{index > 0 ? 'and ' : ''}{conditionInfo?.conditionType === 'eht_alarm' ? 'an alarm' : 'a circuit'} <b>{label}</b> <u>{compare}</u> <b>{value === '' ? `""` : value}</b>
          {condition.or_null ? ' or ' : ''}
          {condition.or_null ? <b>N/A</b> : null}

        </em>
      );
    });

    return (<p className='generator'>
      When
      {conditionsText}
      &nbsp;
      {rule.get('alarm_group', 'None') ? 'then alarm group is' : ''}

      {rule.get('alarm_group', 'None') ? < b style={{ marginRight: '5px', marginLeft: '5px' }}>{rule.get('alarm_group', 'None')}</b> : ''}
      {rule.get('alarm_group', 'None') ? 'and ' : ' then '}
      criticality is
      <b style={{ marginRight: '5px', marginLeft: '5px' }}>{criticalityLabels[rule.get('criticality')]}</b>

    </p>
    );
  };

  const onDelete = (ruleId) => {
    dispatch(deleteRule(ruleId));
  };

  const buildSummary = (showButtons: boolean) => {
    return (
      <table className='ruleTitle'>
        <tbody>
          <tr>
            <td className='ruleNumber'>
              {`Rule ${orderNumber + 1}`}
            </td>
            <td>{titleCreation()}</td>
            <td style={{ width: '80px' }}>
              <div className='ruleTitleButtons'>{showButtons ? (<>
                <Button onClick={() => onDelete(rule.get('id'))} icon={<DeleteIcon className='deleteButton' />} />
                <SwapVertIcon style={{ color: '#444', fontSize: 24, height: '40px', margin: 'auto' }} />
                {rule.get('pager_duty_notification') ?
                  <Tooltip title='Will notify PagerDuty'>
                    <img src={PagerDutySvg} style={{ height: '16px', width: '12px', marginTop: '13px', marginLeft: '10px' }} />
                  </Tooltip> : null
                }
              </>) : null}
              </div>

            </td>

          </tr>
        </tbody>
      </table>
    );
  };

  return (<>
    <PermissionFence can={['edit-eht-alarm-rule']} noPermissionComponent={(
      <Paper>
        {buildSummary(false)}
      </Paper>
    )}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          {buildSummary(true)}
        </AccordionSummary>
        <AccordionDetails>
          <div className='ruleDetails'>
            <div className='inner'>
              <p className='title1'>If all of the following conditions are met</p>
              {conditions.map((condition, index) => {
                const options = conditionOptions.find((option) => option.value === condition.label);
                return (
                  <Condition key={index} condition={condition} ruleId={rule.get('id')} conditionTypeOptions={conditionTypeOptions} conditionOptions={[options]} />
                );
              })}

              <div className='addConditionButton'>
                <Button onClick={() => addCondition()} cta color="primary" variant="contained" icon={<AddIcon style={{ color: 'white', fontSize: 24, marginRight: '25px' }} />} >Add Condition</Button>
              </div>

              <span className='title2'>
                <hr className='line' />
                <p>The alarm will be categorized with the following</p>
              </span>
              {/* <Result /> */}
              <Result label={'criticality'} value={rule.get('criticality')} ruleId={rule.get('id')} />
              <Result label={'alarm_group'} value={rule.get('alarm_group')} ruleId={rule.get('id')} />
              {
                hasPagerDutyIntegration ?
                  <div className='notifications-container'>
                    <span className='title2'>
                      <hr className='line' />
                      <p>Notifications</p>
                    </span>
                    <Result tooltip={pagerDutyNotificationDisabled ? 'Only available when criticality is set to critical' : ''}  disabled={pagerDutyNotificationDisabled} label={'pager_duty_notification'} value={rule.get('pager_duty_notification')} ruleId={rule.get('id')} />
                  </div> : null
              }

            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </PermissionFence>
  </>);
}

Rule.propTypes = {
  orderNumber: PropTypes.number.isRequired,
  rule: ImmutablePropTypes.map,
  hasPagerDutyIntegration: PropTypes.bool
};

Rule.defaultProps = {
};
