import './GaugeContainer.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Paper from '@mui/material/Paper';
import _ from 'lodash';

import { updateDeviceSettings } from 'src/module/device/action';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import { Grid } from '@mui/material';
import GaugeContent from './GaugeContent';
import { celsiusToFahrenheitConvertor, fahrenheitToCelsiusConvertor } from 'src/utils/utils';
import { getTemperatureUnit } from 'src/module/authentication/selector';
import { deviceTypeConfig } from 'src/utils/deviceTypeConfig';

export default function GaugeContainer (props) {
  const dispatch = useDispatch();
  const {
    device,
    deviceStatus,
    deviceId,
    configFields,
    gaugeStyle,
    canDisableAllAlarms,
    canDisableControlAlarms,
    enableSpecificAlarms,
    isDeviceInCommFail
  } = props;

  const temperatureUnit = useSelector(getTemperatureUnit);

  let loading = true;
  let hasSettings = false;
  let controlTemperatureSetpoint = device.getIn(['settings', 'controlTemperatureSetpoint']);
  const highTemperatureTripSetpoint = device.getIn(['settings', 'highTemperatureTripSetpoint']);

  let highControlTemperatureAlarmSetpoint = device.getIn(['settings', 'highControlTemperatureAlarmSetpoint']);
  let lowControlTemperatureAlarmSetpoint = device.getIn(['settings', 'lowControlTemperatureAlarmSetpoint']);
  const highLoadCurrentAlarmSetpoint = device.getIn(['settings', 'highLoadCurrentAlarmSetpoint']);
  const lowLoadCurrentAlarmSetpoint = device.getIn(['settings', 'lowLoadCurrentAlarmSetpoint']);
  const highLoadCurrentTripSetpoint = device.getIn(['settings', 'highLoadCurrentTripSetpoint']);

  const highGroundCurrentAlarmSetpoint = device.getIn(['settings', 'highGroundCurrentAlarmSetpoint']);
  const groundFaultTripCurrentSetpoint = device.getIn(['settings', 'groundFaultTripCurrentSetpoint']);
  const controlTemperatureToolTip = device.getIn(['settings', 'controlTemperatureToolTip']);
  const temperatureSensorsToolTip = device.getIn(['settings', 'temperatureSensorsToolTip']);

  const showPrimaryGaugesByDefault = deviceTypeConfig[device.get('type')]?.showPrimaryGaugesByDefault;
  const hideStatus = device.getIn(['settings', 'hideStatus'], !showPrimaryGaugesByDefault);
  const hideGroundFault = device.getIn(['settings', 'hideGroundFaultGauge'], !showPrimaryGaugesByDefault);
  const hideLoadCurrent = device.getIn(['settings', 'hideLoadCurrentGauge'], !showPrimaryGaugesByDefault);
  const hideControlTemp = device.getIn(['settings', 'hideControlTempGauge'], !showPrimaryGaugesByDefault);
  const showInnerPanelTemperature = device.getIn(['settings', 'showInnerPanelTemperatureGauge'], false);

  let innerPanelTemperatureMax = device.getIn(['settings', 'innerPanelTemperatureMax'], false);
  const showStatusBlock = !(hideStatus && hideGroundFault && hideLoadCurrent && hideControlTemp && !showInnerPanelTemperature);

  const temperatureSensorSettings = device.getIn(['settings', 'temperatureSensors']);
  const temperatureSensorStatus = device.getIn(['status', 'temperatureSensors']);

  const temperatureSensors = {};

  if (temperatureSensorSettings && temperatureSensorSettings.size) {
    temperatureSensorSettings.map((value, key) => {
      temperatureSensors[key] = Map({
        settings: value,
        status: temperatureSensorStatus ? temperatureSensorStatus.get(key, null) : null
      });
    });
  }

  let controlTemperature = deviceStatus?.size > 0 ? deviceStatus.get('controlTemperature', null) : null;
  let innerPanelTemperature = deviceStatus?.size > 0 ? deviceStatus.get('innerPanelTemperature', null) : null;
  const loadCurrent = deviceStatus?.size > 0 ? deviceStatus.get('loadCurrent', null) : null;
  const groundCurrent = deviceStatus?.size > 0 ? deviceStatus.get('groundCurrent', null) : null;
  const circuitOn = device.getIn(['status', 'circuitOn'], false);
  const rtdConfigValue = device.getIn(['settings', 'rtdConfig'], null);
  const switchControlModeValue = device.getIn(['settings', 'switchControlMode'], null);
  const [deviceOperatingStatus, setDeviceOperatingStatus] = useState('N/A');

  if (temperatureUnit === 'F') {
    controlTemperatureSetpoint = controlTemperatureSetpoint != null ? celsiusToFahrenheitConvertor(controlTemperatureSetpoint) : null;
    highControlTemperatureAlarmSetpoint = highControlTemperatureAlarmSetpoint != null ? celsiusToFahrenheitConvertor(highControlTemperatureAlarmSetpoint) : null;
    lowControlTemperatureAlarmSetpoint = lowControlTemperatureAlarmSetpoint != null ? celsiusToFahrenheitConvertor(lowControlTemperatureAlarmSetpoint) : null;
    controlTemperature = controlTemperature != null ? celsiusToFahrenheitConvertor(controlTemperature) : null;
    innerPanelTemperature = innerPanelTemperature != null ? celsiusToFahrenheitConvertor(innerPanelTemperature) : null;
    innerPanelTemperatureMax = innerPanelTemperatureMax != null ? celsiusToFahrenheitConvertor(innerPanelTemperatureMax) : null;
  }

  let rtdConfigLabel = device.getIn(['settings', 'rtdConfigLabel']) || 'N/A';
  if (rtdConfigLabel === 'N/A') {
    // if a device has rtd config field in advanced settings
    if (rtdConfigValue != null) {
      const options = configFields?.getIn(['settings', 'rtdConfig', 'options']);
      rtdConfigLabel = options?.find(option => option.get('value') == rtdConfigValue)?.get('label') || rtdConfigLabel;
    }
  }

  // let switchControlModeLabel = 'N/A';
  if (switchControlModeValue != null) {
    //const options = configFields?.getIn(['settings', 'switchControlMode', 'options']);
    // switchControlModeLabel = options?.find(option => option.get('value') == switchControlModeValue)?.get('label') || switchControlModeLabel;
  }

  const handleGaugeSettingChange = (deviceId: string, type: string, id: number = null) => {
    return (values: any) => {
      let rec = null;
      const canDisableAllAlarms = values.canDisableAllAlarms;
      Object.entries(values).forEach(([field, dirtyValue]) => {
        let value = (dirtyValue === null || dirtyValue === '') ? null : Math.round(parseFloat(dirtyValue as string) * 10) / 10;

        let obj: any = {};
        const settingsMap = {
          voltage: {
            rangeMin: 'lowVoltageAlarmSetpoint',
            rangeMax: 'highVoltageAlarmSetpoint',
            rangeMinSwitch: 'lowVoltageAlarmMask',
            rangeMaxSwitch: 'highVoltageAlarmMask'
          },
          'load-current': {
            rangeMin: 'lowLoadCurrentAlarmSetpoint',
            rangeMax: 'highLoadCurrentAlarmSetpoint',
            rangeTrip: 'highLoadCurrentTripSetpoint',
            rangeMinSwitch: 'lowLoadCurrentAlarmMask',
            rangeMaxSwitch: 'highLoadCurrentAlarmMask'
          },
          'ground-current': {
            rangeMin: 'highGroundCurrentAlarmSetpoint',
            rangeMax: 'groundFaultTripCurrentSetpoint',
            rangeMinSwitch: 'highGroundFaultCurrentAlarmMask',
            rangeMaxSwitch: 'groundFaultTripAlarmMask'
          },
          'control-temperature': {
            rangeMin: 'lowControlTemperatureAlarmSetpoint',
            rangeMax: 'highControlTemperatureAlarmSetpoint',
            setting: 'controlTemperatureSetpoint',
            rangeTrip: 'highTemperatureTripSetpoint',
            rangeMinSwitch: `lowControlAlarmMask`,
            rangeMaxSwitch: `highControlAlarmMask`,
            isTemp: true
          },
          'temperature-sensor': {
            rangeMin: `temperatureSensors.${id}.lowTemperatureAlarmSetpoint`,
            rangeMax: `temperatureSensors.${id}.highTemperatureAlarmSetpoint`,
            rangeMinSwitch: `lowTS${id}AlarmMask`,
            rangeMaxSwitch: `highTS${id}AlarmMask`,
            isTemp: true
          }
        };

        if (settingsMap[type].isTemp) {
          value = temperatureUnit === 'F' ? fahrenheitToCelsiusConvertor(value) : value;
        }
        if ((canDisableAllAlarms || enableSpecificAlarms?.includes(settingsMap[type][field])) && (field === 'rangeMinSwitch' || field === 'rangeMaxSwitch')) {
          value = dirtyValue ? 1 : 0;
        }
        if ((field.includes('Switch') && (canDisableAllAlarms || enableSpecificAlarms?.includes(settingsMap[type][field]))) || ((field === 'rangeMin' || field === 'rangeMax' || field === 'rangeTrip') && value !== null) || (type === 'control-temperature' && field === 'setting')) {
          const key = settingsMap[type][field];

          if (type === 'temperature-sensor') {
            obj = { settings: { temperatureSensors: {} } };
          }
          obj = _.set(obj, `settings.${key}`, value);
        }

        rec = _.merge(rec, obj);
      });

      if (rec) {
        dispatch(updateDeviceSettings(deviceId, Map(rec)));
      }
    };
  };

  const renderStatusBlock = () => {
    return (
      <div id='gauge-container' data-testid='gauge-container'>
        {loading ?
          <Paper>
            <h3>Status</h3>
            <div className='grid-gauge dummy-gauge'>
              <div className='gauge-overlay'>
                <div className='gauge-large-loading'>
                  <LogoSpinner size={160} />
                </div>
              </div>
              {dummyGauges.map(gauge => {
                return <GaugeContent
                  deviceId={null}
                  key={gauge.type}
                  title={gauge.type}
                  unit={gauge.unit}
                  type={gauge.id}
                  rangeMin={null}
                  rangeMax={null}
                  currentVal={null}
                  rangeMinEnableLabel=''
                  rangeMaxEnableLabel=''
                  rangeMinLabel=''
                  rangeMaxLabel=''
                  gaugeStyle={gaugeStyle}
                />;
              })}
            </div>
          </Paper> :
          hasSettings ?
            <Paper>
              <h3 className='gauge-container-title'>Status</h3>

              {!hideStatus ? (
                <Grid key={12} container spacing={1}>
                  <Grid key={5} item md={6} xs={12} className='device-status'>
                    <h4>Device Status:</h4>
                    <p>&nbsp;{deviceOperatingStatus}</p>
                  </Grid>
                  <Grid key={789} item md={6} xs={12} className='device-status'>
                    <h4>RTD Config:</h4>
                    <p>&nbsp;{rtdConfigLabel}</p>
                  </Grid>
                </Grid>
              ) : null}

              <div className='grid-gauge'>
                {!hideControlTemp ?
                  <GaugeContent
                    deviceId={deviceId}
                    title={'Control Temperature'}
                    unit={temperatureUnit === 'F' ? '°F' : '°C'}
                    rangeMin={lowControlTemperatureAlarmSetpoint}
                    rangeMax={highControlTemperatureAlarmSetpoint}
                    rangeTrip={highTemperatureTripSetpoint}
                    setting={controlTemperatureSetpoint}
                    currentVal={controlTemperature}
                    type={'GAUGE_CONTROL_TEMPERATURE'}
                    key={'GAUGE_CONTROL_TEMPERATURE'}
                    canDisableAllAlarms={canDisableControlAlarms}
                    enableLowAlarm={false}
                    enableHighAlarm={false}
                    intialHighSetpointEnabled={device.getIn(['settings', 'highControlAlarmMask']) === 1 ? true : false}
                    intialLowSetpointEnabled={device.getIn(['settings', 'lowControlAlarmMask']) === 1 ? true : false}
                    rangeMinEnableLabel='Enable Low Control Temperature Alarm'
                    rangeMaxEnableLabel='Enable High Control Temperature Alarm'
                    rangeMinLabel='Low Control Temperature Alarm Setpoint'
                    rangeMaxLabel='High Control Temperature Alarm Setpoint'
                    rangeTripLabel='High Temperature Trip Setpoint'
                    rangeMinDisabledLabel="Device does not support disabling Low Control Temp Alarm"
                    rangeMaxDisabledLabel="Device does not support disabling High Control Temp Alarm"
                    settingLabel='Control Temperature Setpoint'
                    handleSettingChange={handleGaugeSettingChange(deviceId, 'control-temperature')}
                    gaugeStyle={gaugeStyle}
                    toolTip={controlTemperatureToolTip}
                    theme={isDeviceInCommFail ? 'off' : 'temp'}
                  />
                  : null}

                {!hideGroundFault ?
                  <GaugeContent
                    deviceId={deviceId}
                    title={'Ground Fault Current'}
                    unit={'mA'}
                    rangeMin={highGroundCurrentAlarmSetpoint}
                    rangeMax={groundFaultTripCurrentSetpoint}
                    currentVal={groundCurrent}
                    canDisableAllAlarms={canDisableAllAlarms}
                    enableLowAlarm={enableSpecificAlarms?.includes('highGroundFaultCurrentAlarmMask')}
                    enableHighAlarm={enableSpecificAlarms?.includes('groundFaultTripAlarmMask')}
                    intialHighSetpointEnabled={device.getIn(['settings', 'groundFaultTripAlarmMask']) === 1 ? true : false}
                    intialLowSetpointEnabled={device.getIn(['settings', 'highGroundFaultCurrentAlarmMask']) === 1 ? true : false}
                    type={'GAUGE_GROUND_FAULT_CURRENT'}
                    key={'GAUGE_GROUND_FAULT_CURRENT'}
                    rangeMinEnableLabel='Enable High Ground Current Alarm'
                    rangeMaxEnableLabel='Enable Ground Current Trip'
                    rangeMinLabel='High Ground Current Alarm Setpoint'
                    rangeMaxLabel='Ground Fault Trip Current Setpoint'
                    rangeMinDisabledLabel="Device does not support disabling High Ground Current Alarm"
                    rangeMaxDisabledLabel="Device does not support disabling Ground Current Trip Alarm"
                    handleSettingChange={handleGaugeSettingChange(deviceId, 'ground-current')}
                    theme={isDeviceInCommFail ? 'off' : 'rising'}
                    gaugeStyle={gaugeStyle}
                    setting={null}
                  />
                  : null}

                {!hideLoadCurrent ?
                  <GaugeContent
                    deviceId={deviceId}
                    title={'Load Current'}
                    unit={'A'}
                    canDisableAllAlarms={canDisableAllAlarms}
                    enableLowAlarm={enableSpecificAlarms?.includes('lowLoadCurrentAlarmMask')}
                    enableHighAlarm={enableSpecificAlarms?.includes('highLoadCurrentAlarmMask')}
                    intialHighSetpointEnabled={device.getIn(['settings', 'highLoadCurrentAlarmMask']) === 1 ? true : false}
                    intialLowSetpointEnabled={device.getIn(['settings', 'lowLoadCurrentAlarmMask']) === 1 ? true : false}
                    rangeMin={lowLoadCurrentAlarmSetpoint}
                    rangeMax={highLoadCurrentAlarmSetpoint}
                    rangeTrip={highLoadCurrentTripSetpoint}
                    currentVal={loadCurrent}
                    type={'GAUGE_LOAD_CURRENT'}
                    key={'GAUGE_LOAD_CURRENT'}
                    rangeMinEnableLabel='Enable Low Load Alarm'
                    rangeMaxEnableLabel='Enable High Load Alarm'
                    rangeMinLabel='Low Load Current Alarm Setpoint'
                    rangeMaxLabel='High Load Current Alarm Setpoint'
                    rangeTripLabel='High Load Current Trip Setpoint'
                    rangeMinDisabledLabel="Device does not support disabling Low Load Current Alarm"
                    rangeMaxDisabledLabel="Device does not support disabling High Load Current Alarm"
                    handleSettingChange={handleGaugeSettingChange(deviceId, 'load-current')}
                    theme={isDeviceInCommFail ? 'off' : (circuitOn ? 'default' : 'off')}
                    gaugeStyle={gaugeStyle}
                  />
                  : null}

                {showInnerPanelTemperature ?
                  <GaugeContent
                    deviceId={deviceId}
                    title={'Inner Panel Temperature'}
                    unit={temperatureUnit === 'F' ? '°F' : '°C'}
                    rangeMin={null}
                    rangeMax={innerPanelTemperatureMax}
                    currentVal={innerPanelTemperature}
                    type={'GAUGE_INNER_PANEL_TEMPERATURE'}
                    key={'GAUGE_INNER_PANEL_TEMPERATURE'}
                    canDisableAllAlarms={false}
                    enableLowAlarm={false}
                    enableHighAlarm={false}
                    intialHighSetpointEnabled={false}
                    intialLowSetpointEnabled={false}
                    settingLabel='Inner Panel Temperature'
                    gaugeStyle={gaugeStyle}
                    theme={isDeviceInCommFail ? 'off' : 'temp'}
                    disableHamburger
                  />
                  : null}

                {Object.keys(temperatureSensors).map((key) => {
                  let sensor = temperatureSensors[key];

                  if (!sensor) {
                    sensor = Map();
                  }
                  if (!sensor.getIn(['status', 'temperature'], false) && sensor.getIn(['status', 'temperature']) !== 0 && sensor.getIn(['status', 'temperature']) !== null) {
                    return null;
                  }
                  const lowTemperatureAlarmSetpoint = sensor.getIn(['settings', 'lowTemperatureAlarmSetpoint']);
                  const highTemperatureAlarmSetpoint = sensor.getIn(['settings', 'highTemperatureAlarmSetpoint']);
                  const temperature = sensor.getIn(['status', 'temperature']);
                  return <GaugeContent
                    deviceId={deviceId}
                    key={key}
                    title={`Temperature Sensor ${key}`}
                    unit={temperatureUnit === 'F' ? '°F' : '°C'}
                    canDisableAllAlarms={canDisableAllAlarms}
                    enableLowAlarm={enableSpecificAlarms?.includes(`lowTS${key}AlarmMask`)}
                    enableHighAlarm={enableSpecificAlarms?.includes(`highTS${key}AlarmMask`)}
                    intialHighSetpointEnabled={device.getIn(['settings', `highTS${key}AlarmMask`]) === 1 ? true : false}
                    intialLowSetpointEnabled={device.getIn(['settings', `lowTS${key}AlarmMask`]) === 1 ? true : false}
                    rangeMin={temperatureUnit === 'F' && lowTemperatureAlarmSetpoint != null ? celsiusToFahrenheitConvertor(lowTemperatureAlarmSetpoint) : lowTemperatureAlarmSetpoint}
                    rangeMax={temperatureUnit === 'F' && highTemperatureAlarmSetpoint != null ? celsiusToFahrenheitConvertor(highTemperatureAlarmSetpoint) : highTemperatureAlarmSetpoint}
                    currentVal={temperatureUnit === 'F' && temperature != null ? celsiusToFahrenheitConvertor(temperature) : temperature}
                    type={`GAUGE_TEMPERATURE_SENSOR_${key}`}
                    rangeMinEnableLabel='Enable Low Temperature Alarm'
                    rangeMaxEnableLabel='Enable High Temperature Alarm'
                    rangeMinLabel='Low Temperature Alarm Setpoint'
                    rangeMaxLabel='High Temperature Alarm Setpoint'
                    rangeMinDisabledLabel="Device does not support disabling Low Temperature Alarm"
                    rangeMaxDisabledLabel="Device does not support disabling High Temperature Alarm"
                    handleSettingChange={handleGaugeSettingChange(deviceId, 'temperature-sensor', parseInt(key))}
                    gaugeStyle={gaugeStyle}
                    toolTip={device.getIn(['settings', 'excludeSensorsFromTooltip'], []).includes(key) ? null : temperatureSensorsToolTip}
                    theme={isDeviceInCommFail ? 'off' : (sensor.getIn(['status', 'temperature']) === null ? 'off' : 'temp')}
                    settingPrefix={`Temperature Sensor ${key} `}
                  />;
                })
                }
              </div>
            </Paper>
            : null
        }
      </div>
    );
  };

  if (typeof device.get('settings') !== 'undefined') {
    if (device.get('settings').size > 0) {
      hasSettings = true;
    }
    loading = false;
  }

  const GetCurrentDeviceOperatingStatusLabel = (val: number) => {
    try {
      return configFields.getIn(['settings', 'deviceOperatingStatus', 'options'], [])
        .find(option => option.get('value', null) == val).get('label', null) || null;
    }
    catch {
      return null;
    }
  };

  useEffect(() => {
    const status = GetCurrentDeviceOperatingStatusLabel(device.getIn(['settings', 'deviceOperatingStatus'], null));
    if (deviceOperatingStatus !== status && status) {
      setDeviceOperatingStatus(status);
    }
  }, [device, configFields]);

  const dummyGauges = [
    { type: 'Control Temperature', unit: 'C', id: 'GAUGE_CONTROL_TEMPERATURE' },
    { type: 'Ground Fault Current', unit: 'mA', id: 'GAUGE_GROUND_FAULT_CURRENT' },
    { type: 'Load Current', unit: 'A', id: 'GAUGE_LOAD_CURRENT' },
    { type: 'Temperature Sensor 1', unit: 'C', id: 'GAUGE_TEMPERATURE_SENSOR_1' },
    { type: 'Temperature Sensor 2', unit: 'C', id: 'GAUGE_TEMPERATURE_SENSOR_2' },
    { type: 'Power Consumption', unit: 'w', id: 'GAUGE_POWER_CONSUMPTION' }
  ];

  return showStatusBlock ? renderStatusBlock() : null;
}

GaugeContainer.propTypes = {
  device: PropTypes.object,
  deviceStatus: PropTypes.object,
  deviceId: PropTypes.string,
  configFields: PropTypes.object,
  gaugeStyle: PropTypes.string,
  canDisableAllAlarms: PropTypes.bool,
  canDisableControlAlarms: PropTypes.bool,
  isDeviceInCommFail: PropTypes.bool,
  enableSpecificAlarms: PropTypes.object
};

GaugeContainer.defaultProps = {
  device: Map(),
  deviceStatus: Map(),
  deviceId: String,
  configFields: Map(),
  canDisableAllAlarms: false,
  canDisableControlAlarms: false,
  isDeviceInCommFail: false,
  enableSpecificAlarms: null
};
