import './CustomStatistics.scss';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Carousel from 'react-material-ui-carousel';
import NumberFormat from 'react-number-format';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import _ from 'lodash';
import { getClient } from 'src/module/authentication/selector';

import MajorStat from 'src/component/UI/Statistics/MajorStat';
import MinorStat from 'src/component/UI/Statistics/MinorStat';
import AlarmHistory from 'src/component/AlarmHistory';
import SensorHistory from 'src/component/SensorHistory';
import AlarmsByStatusGraph from 'src/component/AlarmsByStatusGraph';
import WorkCompletedGraph from 'src/component/WorkCompletedGraph';
import CriticalAlarms from 'src/screen/Protected/DashboardScreen/CriticalAlarms';
import NewWorkTickets from 'src/component/NewWorkTickets';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import WeatherForecast from '../WeatherForecast';
import { functionMapping } from "src/utils/statFunctions";
import FeatureFence from 'src/component/FeatureFence';
export default function CustomStatistics (props) {
  const {
    layoutPreferencePath,
    defaultLayout,
    variables,
    interpolatedVariables,
    styleOverrides
  } = props;

  const client = useSelector(getClient);

  const buildStat = (stat, defaultValue) => {
    if (variables && stat && stat.split) {
      const statParts = stat.split('.');
      const obj = statParts.shift();

      if (variables[obj] && variables[obj].getIn) {
        return variables[obj].getIn(statParts, defaultValue);
      } else if (typeof variables[obj] !== 'undefined') {
        return variables[obj];
      }
    }
    return defaultValue;
  };

  let layout = defaultLayout;
  const preferenceRoots = {
    client
  };

  if (layoutPreferencePath) {
    const layoutPreferencePathParts = layoutPreferencePath.split('.');
    const preferenceRoot = layoutPreferencePathParts.shift();

    if (preferenceRoots[preferenceRoot]) {
      const layoutRaw = preferenceRoots[preferenceRoot].getIn(layoutPreferencePathParts, null);
      if (layoutRaw) {
        layout = layoutRaw.toJS();
      }
    }
  }

  const formatStat = (stat, formatFunction = null) => {
    if (formatFunction) {
      stat = _.get(functionMapping, formatFunction, (x) => x)(stat);
    }
    if (typeof stat === 'number') {
      return (<NumberFormat value={stat} displayType={'text'} thousandSeparator={true} />);
    }

    return stat;
  };

  useEffect(() => {
    buildCustomStats(layout);
  }, [interpolatedVariables]);

  const formatText = (text, value, dynamicText=null) => {
    if (dynamicText) {
      return _.get(functionMapping, dynamicText, () => text)(value);
    }
    if (interpolatedVariables) {
      return Object.keys(interpolatedVariables).reduce((acc, key) => {
        const textVar = interpolatedVariables[key];
        return acc.replaceAll(key, buildStat(textVar.valuePath, textVar.default));
      }, text);
    }

    return text;
  };

  const buildCarousel = (obj: any) => {
    return (<Carousel key={key++} interval={obj.interval} className='carousel'>
      {obj.children ? buildCustomStats(obj.children) : null}
    </Carousel>);
  };

  let key = 0;

  const buildGrid = (obj) => {
    if (obj.container) {
      return (
        <Grid key={key++} container direction={obj.direction || 'row'} item={obj.item || false} spacing={obj.spacing}>
          {obj.children ? buildCustomStats(obj.children) : null}
        </Grid>
      );
    } else if (obj.feature) {
      return (
        <FeatureFence key={key++} can={obj.feature} >
          <Grid className={obj.className || null} item xs={obj.xs || null} md={obj.md || null} lg={obj.lg || null} xl={obj.xl || null}>
            {obj.children ? buildCustomStats(obj.children) : null}
          </Grid>
        </FeatureFence>
      );
    } else {
      return (
        <Grid key={key++} className={obj.className || null} item xs={obj.xs || null} md={obj.md || null} lg={obj.lg || null} xl={obj.xl || null}>
          {obj.children ? buildCustomStats(obj.children) : null}
        </Grid>
      );
    }
  };

  const buildMasonry = (obj) => {
    return (
      <Box key={key++} width={obj.width || null}>
        <Masonry key={key++}  columns={obj.columns || { md: 1 }} spacing={obj.spacing || 1}>
          {obj.children ? buildCustomStats(obj.children) : null}
        </Masonry>
      </Box>
    );
  };

  const titleStyle = { fontSize: '12pt', fontWeight: 'bold', textAlign: 'center' };

  const buildBlock = (obj) => {
    return (
      <Paper key={key++} className={obj.className || null}>
        <div style={(styleOverrides && styleOverrides.title) ? styleOverrides.title : titleStyle }>{formatText(obj.title || '', null)}</div>
        <div className='stat-box'>
          {obj.children ? buildCustomStats(obj.children) : null}
        </div>
      </Paper>
    );
  };

  const buildComponent = (obj) => {
    // TODO: Investigate if React.lazy can be used to dynamically load components
    let component = null;
    switch (obj.componentName) {
      case 'AlarmHistory':
        component = <AlarmHistory key={key++} />;
        break;
      case 'SensorHistory':
        component = <SensorHistory key={key++} />;
        break;
      case 'AlarmsByStatusGraph':
        component = <AlarmsByStatusGraph key={key++} />;
        break;
      case 'WorkCompletedGraph':
        component = <WorkCompletedGraph key={key++} />;
        break;
      case 'CriticalAlarms':
        component = <CriticalAlarms key={key++} />;
        break;
      case 'NewWorkTickets':
        component = <NewWorkTickets key={key++} />;
        break;
      case 'WeatherForecast':
        component = <WeatherForecast key={key++} />;
        break;
      case 'DeviceRotate':
        component = <DeviceRotate key={key++} />;
        break;
    }

    return component;
  };

  const buildMajor = (obj) => {
    const stat = buildStat(obj.value, obj.defaultValue);
    const helpText = formatText(obj.helpText || '', stat, obj.dynamicHelpText);
    const formattedStat = formatStat(stat, obj.formatFunction);

    return (
      <MajorStat key={key++}
        title={formatText(obj.title, stat, obj.dynamicTitle)}
        helpText={helpText || null}
        stat={formattedStat}
        style={(styleOverrides === null || !styleOverrides.major) ? null : styleOverrides.major}
      />
    );
  };

  const buildMinor = (obj) => {
    const helpText = formatText(obj.helpText || '', null);
    const stat = buildStat(obj.value, obj.defaultValue);
    const formattedStat = formatStat(stat, obj.formatFunction);

    return (
      <MinorStat key={key++}
        title={formatText(obj.title, stat, obj.dynamicTitle)}
        helpText={helpText || null}
        stat={formattedStat}
        style={(styleOverrides === null || !styleOverrides.minor) ? null : styleOverrides.minor}
      />
    );
  };

  const buildModule = {
    carousel: buildCarousel,
    masonry: buildMasonry,
    grid: buildGrid,
    block: buildBlock,
    major: buildMajor,
    minor: buildMinor,
    component: buildComponent
  };

  const buildCustomStats = (layout) => {
    return layout.map((obj, idx) => {
      const module = buildModule[obj.type](obj);
      if (obj.feature) {
        return (
          <FeatureFence key={`${obj.type}-${idx}`} can={obj.feature} >
            {module}
          </FeatureFence>
        );
      }
      return module;
    });
  };

  return (
    <div data-testid='custom-statistics' className='customizable-statistics'>
      {buildCustomStats(layout)}
    </div>
  );
}

CustomStatistics.propTypes = {
  layoutPreferencePath: PropTypes.string,
  variables: PropTypes.object,
  interpolatedVariables: PropTypes.object,
  defaultLayout: PropTypes.array.isRequired,
  styleOverrides: PropTypes.object
};

CustomStatistics.defaultProps = {
  styleOverrides: null
};
