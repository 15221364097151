import './SensorHistory.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { InputLabel, MenuItem, Select } from '@mui/material';

import PlaceholderChart from 'src/screen/Protected/DashboardScreen/assets/placeholder-chart.png';
import variables from 'src/style/variable/variables.module.scss';
import { fetchSensorHistory } from 'src/module/device/action';
import { getSensorData, getSensorDateLoading } from 'src/module/device/selector';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import LogoSpinner from './UI/LogoSpinner';
import { getConvertAllGraphValuesBasedOnMaxKwh, getKWHTitle } from 'src/utils/statFunctions';
import { Chart } from 'react-chartjs-2';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';


export default function SensorHistory () {
  const [timeFrame, setTimeFrame] = React.useState('3-months');

  const filters = useSelector(getDevicesSelectedFilters);
  const sensorData = useSelector(getSensorData(timeFrame, 'Monthly'));
  const loading = useSelector(getSensorDateLoading());
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const kwhValues = sensorData.get('kwh')?.map(x => x.get('kwh')).toJS();

  const maxKwh = sensorData.get('kwh') ? Math.max(...kwhValues) : 0;
  const kwhTitle = getKWHTitle(maxKwh);

  const convertKWH = getConvertAllGraphValuesBasedOnMaxKwh(maxKwh);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSensorHistory(filters, timeFrame, 'Monthly'));
  }, [timeFrame, filters]);


  const timeFrameChange = (event) => {
    const val = event.target.value as string;
    setTimeFrame(val);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    lineTension: 0.5,
    stacked: false,
    elements: {
      point: {
        radius: 0
      }
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        labels: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          },
        }
      }
    },
    scales: {
      x: {
        title: { text: 'Monthly', display: true, font: {
          size: 15,
          color: '#000000',
          family: 'Montserrat'
        } },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          text: 'Temperature', display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
          display: false
        },
        title: {
          text: kwhTitle, display: true, font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        },
        ticks: {
          font: {
            size: 15,
            color: '#000000',
            family: 'Montserrat'
          }
        }
      },
    },
  };
  const allData = {
    labels: sensorData.get('kwh')?.map(x => moment(x.get('date_time')).format('YYYY-MM-DD')).toJS(),
    temperature: sensorData.get('weather')?.map(x => x.get('temp')).toJS(),
    kwh: sensorData.get('kwh')?.map(x => convertKWH(x.get('kwh'))).toJS()
  };

  const data = {
    labels: allData.labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Ambient Temperature',
        data: allData.temperature,
        borderColor: variables.quaternary,
        backgroundColor: variables.quaternary,
        yAxisID: 'y'
      },
      {
        type: 'bar' as const,
        label: `Total ${kwhTitle}`,
        data: allData.kwh,
        borderColor: variables.secondary,
        backgroundColor: variables.secondary,
        yAxisID: 'y1',
      }
    ],
  };

  return showRotateMessage ? <DeviceRotate /> : <>
    <div>
      <div className='graph-options'>
        <div>
          <InputLabel >
            Time Frame
          </InputLabel>
          <Select
            id={'timeFrame'}
            labelId={`timeFrame`}
            name={'timeFrame'}
            label="Time Frame"
            value={timeFrame}
            onChange={timeFrameChange}
            style={{ margin: '0px' }}
          >
            <MenuItem value={'3-months'}>3 Months</MenuItem>
            <MenuItem value={'1-year'}>1 Year</MenuItem>
            <MenuItem value={'2-years'}>2 Years</MenuItem>
          </Select>
        </div>

      </div>
      {loading ? <div style={{ width: '300px', height: '500px', margin: 'auto', paddingTop: '200px' }}>
        <LogoSpinner size={200} />
      </div> : null}
    </div>
    {!loading && sensorData?.get('kwh') ? (
      <div style={{ height: '350px', margin: 'auto' }}>
        <Chart type='bar' data={data} options={options} />
      </div>
    ) : null}
    {!loading && !sensorData?.get('kwh') ?


      <div className="placeholder-graph">
        <div className='outer placeholder-graph-overlay'>
          <div className="middle">
            <div className="inner">No History for the Current Selection</div>
          </div>
        </div>
        <img width='100%' src={PlaceholderChart} alt="Placeholder Graph" />
      </div>
      : null
    }
  </>;
}
