import './DataTable.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTable, usePagination, useSortBy, useAsyncDebounce } from 'react-table';
import 'regenerator-runtime/runtime';
import { List, Map } from 'immutable';
import { TextField, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Pagination from './Pagination';
import { updateUser } from 'src/module/user/action';
import { getAuthenticatedUser } from 'src/module/authentication/selector';
import _ from 'lodash';

function Filter ({
  filter,
  setFilter,
  label
}) {
  const [value, setValue] = React.useState(filter);
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined);
  }, 1000);

  return (
    <div className='table-filter'>
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <TextField
          placeholder={label || 'Search Data'}
          variant='standard'
          margin='dense'
          value={value || ''}
          className={'search-field'}
          sx={{ width: '30vw' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </form>
    </div>
  );
}

Filter.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  label: PropTypes.string
};

Filter.defaultProps = {
};

function _handleCellClick (handler, event, row) {
  if (handler && typeof handler == 'function') {
    // don't call parent handler if cell has the ignore class
    const cell = event.currentTarget;
    const ignore = cell && 'classList' in cell && cell.classList.contains('ignore-on-row-click');
    if (!ignore) {
      handler(row);
    }
  }
  event.stopPropagation();
}

function _DataTable (props) {
  const dispatch: any = useDispatch();
  const [data, setData] = useState(List());
  const localPagination = (props.pagination && !props.fetchPage) ? true : false;
  const user = useSelector(getAuthenticatedUser);

  const {
    numPages,
    loading,
    unit,
    columns,
    dense,
    tableId,
    bannerComponent,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable({
    columns: columns,
    data: props.fetchPage ? props.data : data,
    manualPagination: !localPagination,
    pageCount: localPagination ? -1 : props.numPages,
    autoResetPage: false,
    initialState: { pageSize: user?.getIn(['preferences', 'tablePagePreferences', tableId]) || props.paginationPerPage || 10 },
    manualSortBy: true,
    disableMultiSort: true
  }, useSortBy, usePagination);
  const propData = props.data;
  const handleCellClick = props.handleCellClick;
  const [filter, setFilter] = useState('');
  const lastData = useRef({ data: null, filter, pageIndex: null, pageSize: null, sort: undefined, unit: null });
  const fetchPageRef = useRef(props.fetchPage);
  const gotoPageRef = useRef(gotoPage);

  fetchPageRef.current = props.fetchPage;

  // This useEffect only applies if fetchPage is passed in (server side pagination/sorting)
  useEffect(() => {
    if (fetchPageRef && fetchPageRef.current) {
      let sort = null;

      if (sortBy.length && sortBy[0].id) {
        sort = (sortBy[0].desc) ? `-${sortBy[0].id}` : sortBy[0].id;
      }

      if (lastData.current.filter !== filter) {
        gotoPageRef.current(0);
      }

      if (fetchPageRef && fetchPageRef.current) {
        fetchPageRef.current(pageIndex, pageSize, filter ? filter : null, sort);
      }

      lastData.current.filter = filter;
      lastData.current.sort = sort;
    }
  }, [sortBy, unit, filter, pageIndex, pageSize]);

  useEffect(() => {
    if (pageSize !== user?.getIn(['preferences', 'tablePagePreferences', tableId])) {
      dispatch(updateUser(null, Map({
        tablePagePreferences: {
          [tableId]: tableId === 'default' ? 10 : pageSize
        }
      }), true));
    }
  }, [pageSize]);

  // This useEffect only applies if fetchPage is not passed in (client side pagination/sorting)
  useEffect(() => {
    if (!fetchPageRef || !fetchPageRef.current) {

      let sort = null;
      let sortId = '';
      let sortDesc = '';

      if (sortBy.length && sortBy[0].id) {
        sort = (sortBy[0].desc) ? `-${sortBy[0].id}` : sortBy[0].id;
        sortId = sortBy[0].id;
        sortDesc = sortBy[0].desc;
      }

      if (propData !== lastData.current.data || sort !== lastData.current.sort) {
        if (!sortBy.length) {
          setData(propData);
        } else {
          const column = columns.find(column => column.id === sortId);
          if (column) {
            if (sortDesc) {
              if (column.sortDescFunction) {
                setData(propData.sort(column.sortDescFunction));
              } else {
                setData(propData.sort((a, b) => a.get(sortId) < b.get(sortId) ? 1 : -1));
              }
            } else {
              if (column.sortFunction) {
                setData(propData.sort(column.sortFunction));
              } else {
                setData(propData.sort((a, b) => a.get(sortId) > b.get(sortId) ? 1 : -1));
              }
            }
          }
        }
      }

      lastData.current.sort = sort;
      lastData.current.data = propData;
    }
  }, [sortBy, propData, columns]);

  return (
    <>
      {
        bannerComponent ? bannerComponent : null
      }
      {(props.filterable) ? (
        <Filter
          filter={filter}
          setFilter={setFilter}
          label={props.filterLabel}
        />
      ) : null}
      {!props.data.size ? (
        <div data-testid='data-table' className='data-table-placeholder'>{props.placeholder || (<span>There are no records to display</span>)}</div>
      ) : (
        <TableContainer data-testid='data-table' className={loading ? 'data-table-loading' : null} >
          <Table size='small' className={props.pagination ? 'data-table' : 'data-table no-pagination'} {...getTableProps()}>
            <colgroup>
              {props.columnWidths?.map((colWidth, colIndex) => (
                <col key={colIndex} style={{ width: colWidth }} />
              ))}
            </colgroup>

            <TableHead>
              {headerGroups.map((headerGroup, trIndex) => (
                <TableRow key={trIndex} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, thIndex) => (
                    column.sortable !== false ? (
                      <TableCell padding={dense ? 'none' : 'normal'} key={thIndex} {...column.getHeaderProps(column.getSortByToggleProps())} >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ▲'
                              : ' ▼'
                            : ''}
                        </span>
                      </TableCell>
                    ) : (
                      <TableCell padding={dense ? 'none' : 'normal'} key={thIndex} {...column.getHeaderProps()} >
                        {column.render('Header')}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, trIndex) => {
                prepareRow(row);
                return (
                  <TableRow key={trIndex} {...row.getRowProps()}>
                    {row.cells.map((cell, tdIndex) => {
                      let className;

                      if (!cell.column.ClassName) {
                        return (<TableCell padding={dense ? 'none' : 'normal'} key={tdIndex} onClick={(event) => _handleCellClick(handleCellClick, event, row)} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>);
                      } else if (typeof cell.column.ClassName === 'function') {
                        className = cell.column.ClassName(row);
                      } else {
                        className = cell.column.ClassName;
                      }
                      return (<TableCell padding={dense ? 'none' : 'normal'} key={tdIndex} onClick={(event) => _handleCellClick(handleCellClick, event, row)} classes={{ root: className }} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>);
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {props.pagination && (
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSizeOptions={props.paginationRowsPerPageOptions || [10, 25, 50, 100, 250]}
          pageCount={localPagination ? Math.ceil(data.size / pageSize) : numPages}
          totalCount={localPagination ? data.size : props.totalRecords}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          isLoading={loading}
        />
      )}
    </>
  );
}


// because these get stripped from production builds, we need to store this in it's own variable.
const DataTablePropTypes = {
  filterable: PropTypes.bool,
  filterLabel: PropTypes.string,
  pagination: PropTypes.bool,
  unit: PropTypes.string,
  numPages: PropTypes.number,
  totalRecords: PropTypes.number,
  loading: PropTypes.bool,
  data: ImmutablePropTypes.list.isRequired,
  columns: PropTypes.array.isRequired,
  columnWidths: PropTypes.array,
  fetchPage: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  paginationPerPage: PropTypes.number,
  paginationRowsPerPageOptions: PropTypes.array,
  handleCellClick: PropTypes.func,
  dense: PropTypes.bool,
  tableId: PropTypes.string,
  bannerComponent: PropTypes.any
};

_DataTable.propTypes = DataTablePropTypes;

_DataTable.defaultProps = {
  filterable: false,
  filterLabel: '',
  pagination: false,
  unit: '',
  placeholder: null,
  fetchPage: null,
  handleCellClick: null,
  dense: false,
  columnWidths: null,
  tableId: 'default',
  bannerComponent: null
};

const DataTable = React.memo(_DataTable, (prevProps, nextProps) => {
  // before comparing the data, do a compare on the simple elements
  const propKeys = Object.keys(DataTablePropTypes);

  for (let i = 0; i < propKeys.length; i++) {
    const key = propKeys[i];
    if (key === 'data' || key === 'columns' || key === 'columnWidths' || key === 'fetchPage') {
      // columns, columnWidths should not change after initial rendering
      // fetchPage function will change, but we don't need to re-render due to that
      // we will handle comparing data later in this method
      continue;
    }

    if (prevProps[key] !== nextProps[key]) {
      return false;
    }
  }

  const prevData = prevProps.data?.toJS();
  const nextData = nextProps.data?.toJS();

  if (prevData?.length !== nextData?.length) {
    return false;
  }

  const len = prevData?.length || 0;
  for (let i = 0; i < len; i++) {
    if (!_.isEqual(prevData[i], nextData[i])) {
      return false;
    }
  }

  return true;
});

export default DataTable;
