import './SettingsMapPane.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Radio, FormControlLabel } from '@mui/material';
import UpgradeCondition from './UpgradeCondition';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AddIcon from '@mui/icons-material/Add';
import Assignment from './Assignment';
import Button from 'src/component/UI/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getLabelForDeviceModel } from 'src/utils/langLookUp';
import { getDeviceConfigFieldsGeneric, getEnumeratedDeviceSettingsByType } from 'src/module/device/selector';
import { addDeviceUpgradeCondition, deleteDeviceConditionBranch, updateDeviceUpgradeConditionBranch } from 'src/module/upgrade/action';


export default function ConditionBranch (props) {
  const {
    conditionBranch,
    baseDevice,
    upgradeDevice,
    index
  } = props;
  const baseDeviceConfigFields = useSelector(getDeviceConfigFieldsGeneric(baseDevice));
  const deviceSettings = useSelector(getEnumeratedDeviceSettingsByType);
  const [conditionalAssignmentType, setConditionalAssignmentType] = useState(conditionBranch?.get('conditional_value_type', 'select'));
  const dispatch: any = useDispatch();

  function buildSettings (deviceSettings) {
    return deviceSettings.get(baseDevice, List()).map((setting) => {
      return { deviceModel: baseDevice, setting };
    }).concat(buildAdditionalSettings(baseDevice));

  }

  const buildAdditionalSettings = (device) => {
    // get child settings
    if (device?.includes('Controller')) {
      const childDeviceModel = device?.replace('Controller', '');
      return deviceSettings.get(childDeviceModel, List()).map((setting) => {
        return { deviceModel: childDeviceModel, setting };
      });

    }
    // get parent settings
    else {
      const parentDeviceModel = device ? device + 'Controller' : '';
      return deviceSettings.get(parentDeviceModel, List()).map((setting) => {
        return { deviceModel: parentDeviceModel, setting };
      });
    }
  };

  const [baseSettings, setBaseSettings] = useState(buildSettings(deviceSettings));
  useEffect(() => {
    setBaseSettings(buildSettings(deviceSettings));
  }, [baseDevice, deviceSettings, upgradeDevice]);

  const addCondition = () => {
    dispatch(addDeviceUpgradeCondition(conditionBranch.get('id'), { comparison: '=' }));
    return;
  };

  const deleteConditionBranch = () => {
    dispatch(deleteDeviceConditionBranch(conditionBranch.get('id')));
    return;
  };

  const handleConditionalValueUpdated = (value) => {
    dispatch(updateDeviceUpgradeConditionBranch(conditionBranch?.get('id'), { conditional_value: value }));
  };

  const handleConditionalValueTypeUpdated = (type) => {
    setConditionalAssignmentType(type);
    dispatch(updateDeviceUpgradeConditionBranch(conditionBranch?.get('id'), { conditional_value_type: type }));
  };

  return (
    <div className='upgrade-details'>
      <div className='inner'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p className="title1">{index === 0 ? 'However if' : 'Else if'} all the following are true</p>
          <Button className="title1 remove-branch-button"  onClick={deleteConditionBranch} icon={<RemoveCircleOutlineIcon style={{ fontSize: 14 }} />} />
        </div>
        <div className='condition-container'>
          {conditionBranch?.get('conditions')?.map((condition, index) => {
            return (
              <UpgradeCondition key={`${condition?.get('id')}-${index}`} condition={condition} labelOptions={baseSettings} baseSettings={baseDeviceConfigFields} />
            );
          })}
        </div>

        <div className='addConditionButton'>
          <Button onClick={addCondition} cta color="primary" variant="contained" icon={<AddIcon style={{ color: 'white', fontSize: 24, marginRight: '25px' }} />} >Add Condition</Button>
        </div>

        <span className='title2'>
          <hr className='line' />
          <p>Then the {`${getLabelForDeviceModel(conditionBranch?.get('upgrade_device_factory')) || 'Device'} Setting`} will be set to</p>
        </span>
        <FormControlLabel
          value="select"
          control={<Radio />}
          label={<Assignment inputType='select' initialValue={conditionBranch?.get('conditional_value_type') === 'select' ? conditionBranch?.get('conditional_value') : null} onValueUpdated={handleConditionalValueUpdated} disabled={conditionalAssignmentType !== 'select'} baseDeviceModel={conditionBranch?.get('base_device_factory')} baseSettings={baseSettings} upgradeDeviceModel={conditionBranch?.get('upgrade_device_factory')} />}
          onChange={() => { handleConditionalValueTypeUpdated('select'); }}
          name={'conditionalValue'}
          checked={conditionalAssignmentType === 'select'}
        />
        <FormControlLabel
          value="input"
          control={<Radio />}
          label={<Assignment inputType='input' initialValue={conditionBranch?.get('conditional_value_type') === 'input' ? conditionBranch?.get('conditional_value') : null} onValueUpdated={handleConditionalValueUpdated} disabled={conditionalAssignmentType !== 'input'} baseDeviceModel={conditionBranch?.get('base_device_factory')} baseSettings={baseSettings} upgradeDeviceModel={conditionBranch?.get('upgrade_device_factory')} />}
          onChange={() => { handleConditionalValueTypeUpdated('input'); }}
          name={'conditionalValue'}
          checked={conditionalAssignmentType === 'input'}
        />
      </div>
    </div>
  );
}

ConditionBranch.propTypes = {
  conditionBranch: ImmutablePropTypes.map,
  baseDevice: PropTypes.string,
  upgradeDevice: PropTypes.string,
  index: PropTypes.number,
};
