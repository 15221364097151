import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';

import { AbilityContext } from 'src/context/AbilityContext';
import StoreContext from 'src/context/StoreContext';
import ProtectedRoute from 'src/component/ProtectedRoute';
import ChatIntegration from 'src/component/ChatIntegration';

import { getAbilities, getTrainingPassword, getTrainingURL } from 'src/module/authentication/selector';

import AuthenticationScreen from 'src/screen/Authentication/AuthenticationScreen';
import ProgrammingDiscrepancies from 'src/screen/Protected/ProgrammingDiscrepancies/ProgrammingDiscrepancies';
import NotFoundScreen from 'src/screen/Error/NotFoundScreen';

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useIdleTimer } from 'react-idle-timer';
import { triggerActivity } from './module/user/action';
import toast from 'src/utils/toast';

import * as authService from 'src/service/auth';
import DashboardScreen from 'src/screen/Protected/DashboardScreen/DashboardScreen';
import ImportScreen from 'src/screen/Protected/ImportScreen/ImportScreen';
import MaintenanceScreen from 'src/screen/Protected/MaintenanceScreen/MaintenanceScreen';
import AlarmHistoryScreen from 'src/screen/Protected/AlarmsScreen/AlarmHistoryScreen';
import ActiveAlarmScreen from 'src/screen/Protected/AlarmsScreen/ActiveAlarmScreen';
import AboutScreen from 'src/screen/Protected/AboutScreen/AboutScreen';
import IntegrationsScreen from 'src/screen/Protected/IntegrationsScreen/IntegrationsScreen';
import ReportScreen from 'src/screen/Protected/ReportScreen/ReportScreen';
import ClientScreen from 'src/screen/Protected/ClientScreen/ClientScreen';
import NoteScreen from 'src/screen/Protected/NoteScreen/NoteScreen';
import NuisanceScreen from 'src/screen/Protected/NoteScreen/NuisanceScreen';
import LotoScreen from 'src/screen/Protected/NoteScreen/LotoScreen';
import ForcedOnScreen from 'src/screen/Protected/ForcedOnScreen/ForcedOnScreen';
import DeviceScreen from 'src/screen/Protected/DeviceScreen/DeviceScreen';
import ProfileScreen from 'src/screen/Protected/ProfileScreen/ProfileScreen';
import AlarmSimplificationScreen from 'src/screen/Protected/AlarmSimplificationScreen/AlarmSimplificationScreen';
import WorkTicketScreen from 'src/screen/Protected/WorkTicketScreen/WorkTicketScreen';
import RulesScreen from 'src/screen/Protected/RulesScreen/RulesScreen';
import ScheduleScreen from 'src/screen/Protected/ScheduleScreen/ScheduleScreen';
import GuideScreen from 'src/screen/Protected/Wizards/GuideScreen';
import TrainingScreen from 'src/screen/Protected/TrainingScreen/TrainingScreen';
import AllCommLoopsScreen from 'src/screen/Protected/DeviceScreen/AllCommLoopsScreen';
import GroupedDevicesScreen from './screen/Protected/DeviceScreen/GroupedDevicesScreen';
import BatchSchedulesScreen from './screen/Protected/BatchSchedulesScreen/BatchSchedulesScreen';
import BatchConfirmScreen from './screen/Protected/BatchConfirmScreen/BatchConfirmScreen';
import UpgradeDeviceScreen from './screen/Protected/Wizards/UpgradeDeviceScreen';
import PermissionsScreen from './screen/Protected/PermissionsScreen/PermissionsScreen';
import ConfigureSearchScreen from './screen/Protected/DeviceScreen/ConfigureSearchScreen';
import DefineUpgradePathScreen from './screen/Protected/DefineUpgradePathScreen/DefineUpgradePathScreen';
import AdministratorScreen from './screen/Protected/AdministratorScreen/AdministratorScreen';
import AdministratorClientScreen from './screen/Protected/AdministratorClientScreen/AdministratorClientScreen';
import FreezeLogScreen from './screen/Protected/FreezeLogScreen/FreezeLogScreen';
import ServiceAgreementScreen from './screen/Protected/ServiceAgreementScreen/ServiceAgreementScreen';
import { logout } from 'src/module/authentication/action';
import { isWhitelabel } from './utils/utils';
import SubscriptionManagementScreen from './screen/Protected/SubscriptionManagmentScreen/SubscriptionManagmentScreen';
import DeviceGroupsScreen from './screen/Protected/DeviceGroupsScreen/DeviceGroupsScreen';
import GenerateReportsScreen from './screen/Protected/GenerateReportsScreen/GenerateReportsScreen';

export default function App () {
  const dispatch = useDispatch();

  const toastId = React.useRef(null);
  const trainingURL = useSelector(getTrainingURL);
  const trainingPassword = useSelector(getTrainingPassword);

  const checkTokenExpiry = async () => {
    const isTokenExpired = await authService.isTokenExpired();
    if (isTokenExpired) {
      dispatch(logout());
    }
  };


  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_VERSION) {
      hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID), parseInt(process.env.REACT_APP_HOTJAR_VERSION));
    }
    // Initial check
    checkTokenExpiry();
    // Set interval to check token expiry every minute
    const intervalId = setInterval(checkTokenExpiry, 60000);
    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const ability = useSelector(getAbilities);
  const onIdle = () => {
    if ( window.location.pathname.split("/").length - 1 === 3 && window.location.pathname.includes("devices")) {
      dispatch(triggerActivity(true, window.location.pathname));
    }
  };

  const onActive = () => {
    if ( window.location.pathname.split("/").length - 1 === 3 && window.location.pathname.includes("devices")) {
      dispatch(triggerActivity(false, window.location.pathname));
      toast.update(toastId.current, {
        autoClose: 5
      });
      toast.success("Welcome back. Live updates are re-enabled!");
    }
  };
  const onPrompt = () => {
    if ( window.location.pathname.split("/").length - 1 === 3 && window.location.pathname.includes("devices")) {
      toastId.current =  toast.warn(`It looks like you've been away. You won't see active updates for this circuit until you come back!`, {
        autoClose: false,
        className: 'toast success'
      });
    }
  };
  if ( window.location.pathname.split("/").length - 1 === 3 && window.location.pathname.includes("devices")) {
    dispatch(triggerActivity(false, window.location.pathname));
  }


  useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    timeout: 600000,
    promptTimeout: 100,
  });

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <StoreContext.Provider value={{}}>
      <AbilityContext.Provider value={ability}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <ChatIntegration />
        <ToastContainer />
        <Switch>
          <Route exact path='/login/set' component={AuthenticationScreen} />
          <Route exact path='/login/reset' component={AuthenticationScreen} />
          <Route exact path='/login/oauth' component={AuthenticationScreen} />
          <Route exact path='/login' component={AuthenticationScreen} />
          <Route exact path='/logout' component={AuthenticationScreen} />
          <Route exact path='/mfa' component={AuthenticationScreen} />

          <ProtectedRoute exact path='/admin' adminRoute component={AdministratorScreen} can={['smart-trace-admin']} />
          <ProtectedRoute exact path='/admin/client/:client_id' adminRoute component={AdministratorClientScreen} can={['smart-trace-admin']} />
          <ProtectedRoute exact path='/' component={DashboardScreen} can={['read-client']} />
          <ProtectedRoute exact path='/report' component={GuideScreen} can={['edit-device']} />
          <ProtectedRoute exact path='/maintenance' component={MaintenanceScreen} can={['read-eht-alarm', 'read-device']} />
          <ProtectedRoute exact path='/alarms/history' component={AlarmHistoryScreen} can={['read-eht-alarm']} />
          <ProtectedRoute exact path='/alarms/active' component={ActiveAlarmScreen} can={['read-eht-alarm']} />
          <ProtectedRoute exact path='/about' component={AboutScreen} can={['read-client']} />
          <ProtectedRoute exact path='/training' component={TrainingScreen} can={['read-client']} requiredClientPreferences={trainingURL !== null && trainingPassword !== null}/>

          <ProtectedRoute exact path='/settings/facility' component={ClientScreen} can={['edit-client']} />
          <ProtectedRoute exact path='/settings/alarm-simplification' component={AlarmSimplificationScreen} can={['super-admin']} />
          <ProtectedRoute exact path='/settings/upgrade-path' component={DefineUpgradePathScreen} can={['super-admin']} />
          <ProtectedRoute exact path='/settings/rules' component={RulesScreen} can={['read-eht-alarm-rule']} />
          <ProtectedRoute exact path='/settings/integrations' component={IntegrationsScreen} can={['edit-integration']} />
          <ProtectedRoute exact path='/settings/configure-search' component={ConfigureSearchScreen} can={['edit-integration']} />
          <ProtectedRoute exact path='/settings/permissions' component={PermissionsScreen} can={['read-acl']} />
          <ProtectedRoute exact path='/settings/schedule' component={ScheduleScreen} can={['edit-client']} />
          <ProtectedRoute exact path='/settings/license-management' component={SubscriptionManagementScreen} can={['edit-client']} />

          <ProtectedRoute exact path='/import' component={ImportScreen} can={['edit-client']} />
          <ProtectedRoute exact path='/devices' component={GroupedDevicesScreen} can={['read-eht-circuit']} />
          <ProtectedRoute exact path='/devices/groups' component={DeviceGroupsScreen} can={['read-device']} />
          <ProtectedRoute exact path='/devices/reports' component={GenerateReportsScreen} can={['read-device']} />

          <ProtectedRoute exact path='/devices/upgrade' component={UpgradeDeviceScreen} can={['edit-device']} />
          <ProtectedRoute exact path='/devices/comm-loops' component={AllCommLoopsScreen} can={['read-eht-circuit']} />
          <ProtectedRoute exact path='/devices/list/:tab?' component={GroupedDevicesScreen} can={['read-eht-circuit']} />
          <ProtectedRoute exact path='/devices/list/:tab?/byGroup/:deviceGroupId' component={GroupedDevicesScreen} can={['read-eht-circuit']} />
          <ProtectedRoute exact path='/devices/list/:tab?/:templateId?' component={GroupedDevicesScreen} can={['read-eht-circuit']} />
          <ProtectedRoute exact path='/devices/programming-audit' component={ProgrammingDiscrepancies} can={['read-eht-circuit']} />
          <ProtectedRoute exact path='/devices/:device_tag' component={DeviceScreen} can={['read-eht-controller']} />
          <ProtectedRoute exact path='/devices/:device_tag/:child_tag' component={DeviceScreen} can={['read-device']} />

          <ProtectedRoute exact path='/program/list/:tab?' component={BatchSchedulesScreen} can={['read-batch-schedule']} />
          <ProtectedRoute exact path='/program/:batch_id' component={BatchConfirmScreen} can={['read-recipe', 'edit-device']} />

          <ProtectedRoute exact path='/report/send' component={ReportScreen} can={['edit-client']} />
          <ProtectedRoute exact path='/notes/note' component={NoteScreen} can={['read-eht-note']} />
          <ProtectedRoute exact path='/notes/loto' component={LotoScreen} can={['read-eht-note']} />
          <ProtectedRoute exact path='/notes/nuisance' component={NuisanceScreen} can={['read-eht-note']} />
          <ProtectedRoute exact path='/notifications' component={WorkTicketScreen} can={['read-sap-notification']} />
          <ProtectedRoute exact path='/forced-on' component={ForcedOnScreen} can={['read-forced-on-circuit']} requiredFeatures={['forced-on-logs']} />
          <ProtectedRoute exact path='/freeze-logs' component={FreezeLogScreen} can={['read-freeze-log']} requiredFeatures={['freeze-logs']} />
          <ProtectedRoute exact path='/profile' component={ProfileScreen} />
          <ProtectedRoute exact path='/service-agreement' component={ServiceAgreementScreen} />

          <ProtectedRoute component={NotFoundScreen} />
        </Switch>
      </AbilityContext.Provider>
    </StoreContext.Provider>
  );
}
