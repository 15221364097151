import './SettingsModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useFormik } from 'formik';
import { isImmutable, Map } from 'immutable';
import yup from 'src/utils/yup';
import Modal from '@mui/material/Modal';
import Button from 'src/component/UI/Button';
import Grid from '@mui/material/Grid';
import variables from 'src/style/variable/variables.module.scss';
import LockIcon from '@mui/icons-material/Lock';
import SelectField from './SettingsModalFields/SelectField';
import AutoCompleteSelectField from './SettingsModalFields/AutoCompleteSelectField';
import TextField from './SettingsModalFields/TextField';
import DateField from './SettingsModalFields/DateField';
import SwitchField from './SettingsModalFields/SwitchField';
import BitsField from './SettingsModalFields/BitsField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { langLookUpText } from 'src/utils/langLookUp';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
function SettingsModalMemo (props) {
  const {
    fields,
    settings,
    title,
    layout,
    handleChange,
    noModal,
    readonly,
    locked,
    loading,
    fieldSetsOrder,
    dataTestId
  } = props;

  const [enabledVals, setEnabledVals] = useState(Map());
  const [initialValues, setInitialValues] = useState({});
  const [schema, setSchema] = useState(Map());
  const [fieldsetMap, setFieldsetMap] = useState({});

  const lastKnownEnabled = useRef({});
  const lastKnownRequired = useRef({});
  const lastKnownValues = useRef({});



  const fieldComponents = {
    select: SelectField,
    autoCompleteSelect: AutoCompleteSelectField,
    text: TextField,
    number: TextField,
    password: TextField,
    date: DateField,
    switch: SwitchField,
    bits: BitsField,
  };


  const fieldTypes = {
    text: yup.string,
    number: yup.number,
    select: yup.string,
    date: yup.string,
    password: yup.string,
    switch: yup.boolean,
    bits: yup.number
  };

  const typeErrors = {
    number: 'You must specify a number',
    text: 'Required',
    select: 'Required',
    date: 'Please enter a valid date',
    password: 'Required',
    switch: null,
    bits: null,
    default: null
  };

  const bindingsMap = {};
  const enabledSubscriptionsObj = {};
  const requiredSubscriptionsObj = {};
  const fieldIsDisabledById = {};

  let doFieldsMatchSchema = true;
  // check to make sure the scheme matches the fields
  fields.forEach((value, key) => {
    doFieldsMatchSchema = doFieldsMatchSchema ? Boolean(schema.get(key)) : doFieldsMatchSchema;
  });


  const enabledSubscriptions = Map(enabledSubscriptionsObj);
  const requiredSubscriptions: any = Map(requiredSubscriptionsObj);

  const bindings = Object.keys(bindingsMap);
  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      return yup.object().shape(schema.toJS());
    },
    onSubmit: values => {
      let isSame= true;
      Object.keys(values).forEach(key => {
        if (values[key] != initialValues[key]) {
          isSame = false;
          return;
        }
      });
      if (!isSame) {
        props.handleSubmit(Map(values), Map(fieldIsDisabledById));
      } else {
        handleClose();
      }
    },
  });
  const settings_prev = useRef(null);
  const fields_prev = useRef(null);
  useEffect(() => {
    if ((!_.isEqual(settings_prev.current, settings.toJS()) || !_.isEqual(fields_prev.current, fields.toJS()) || Object.keys(formik.values).length === 0)) {
      const validationSchemaShape = {};
      let initialEnabledValues = Map();
      const intVals = fields.reduce((acc, field, key) => {
        acc[key] = settings.get(key);
        const required = field.get('required', true);
        const enabled = field.get('enabled', true);
        if (field.get('binding') === true && typeof initialEnabledValues.get(key) === 'undefined' && typeof acc[key] !== 'undefined') {
          initialEnabledValues = initialEnabledValues.set(key, acc[key]);
        }
        if (typeof required === 'string') {
          if (!requiredSubscriptionsObj[required]) {
            requiredSubscriptionsObj[required] = {};
          }
          requiredSubscriptionsObj[required][key] = field;
          bindingsMap[required] = 1;
        }

        if (typeof enabled === 'string') {
          if (!enabledSubscriptionsObj[enabled]) {
            enabledSubscriptionsObj[enabled] = {};
          }
          enabledSubscriptionsObj[enabled][key] = field;
          bindingsMap[enabled] = 1;
        }

        return acc;
      }, {});
      if (Object.keys(initialValues).length ===0) {
        setInitialValues(intVals);
      }
      setEnabledVals(Map(initialEnabledValues));
      formik.setValues(intVals);


      const orderedFields = fields.sortBy(field => field.get('order'));
      const fieldSetValues = {};
      orderedFields.map((field, fieldId) => {
        if (fieldTypes[field.get('type')]) {
          let required = field.get('required', true);
          if (typeof required === 'string') {
            required = initialValues[required];
          }

          if (required) {
            validationSchemaShape[fieldId] = fieldTypes[field.get('type')](field.get('label'))
              .typeError(typeErrors[field.get('type', 'default')]).required(field.get('errorText'));
          }
          else {
            validationSchemaShape[fieldId] = fieldTypes[field.get('type')](field.get('label')).nullable();
          }
        } else {
          validationSchemaShape[fieldId] = yup.string(field.get('label'));
        }

        for (const [yupSchema, value] of field.get('schema')?.entries() || []) {
          if (value !== null) {
            if (Map.isMap(value)) {
              validationSchemaShape[fieldId] = validationSchemaShape[fieldId][yupSchema](value.get('value'), value.get('message'));
            }
            else {
              validationSchemaShape[fieldId] = validationSchemaShape[fieldId][yupSchema](value);
            }
          }
          else {
            validationSchemaShape[fieldId] = validationSchemaShape[fieldId][yupSchema]();
          }
        }
        if (!fieldSetValues[field.get('fieldset', 'none')]) {
          fieldSetValues[field.get('fieldset', 'none')] = [];
        }
        fieldSetValues[field.get('fieldset', 'none')].push(field.set('id', fieldId));

      });
      setFieldsetMap(fieldSetValues);
      setSchema(Map(validationSchemaShape));
      fields_prev.current = fields.toJS();
      settings_prev.current = settings.toJS();
    }
  }, [props.open, fields]);

  useEffect(() => {
    if (!props.open) {
      formik.resetForm();
      setSchema(Map());
    }
  }, [props.open]);

  useEffect(() => {
    if (bindings.length) {

      let validationSchema = schema;
      requiredSubscriptions.map((subscribedFields: any, boundField) => {
        if (lastKnownRequired.current[boundField] !== formik.values[boundField]) {
          lastKnownRequired.current[boundField] = formik.values[boundField];

          Object.keys(subscribedFields).forEach((key: string) => {
            if (formik.values[boundField] === true) {
              validationSchema = validationSchema.set(key, fieldTypes[
                requiredSubscriptions.getIn([boundField, key, 'type'])
              ](requiredSubscriptions.getIn([boundField, key, 'label']))
                .typeError(typeErrors[requiredSubscriptions.getIn([boundField, key, 'type'], 'default')])
                .required(requiredSubscriptions.getIn([boundField, key, 'errorText']))
              );
            }
            else {
              validationSchema = validationSchema.set(key, fieldTypes[
                requiredSubscriptions.getIn([boundField, key, 'type'])
              ](requiredSubscriptions.getIn([boundField, key, 'label']))
                .typeError(typeErrors[requiredSubscriptions.getIn([boundField, key, 'type'], 'default')])
                .nullable()
              );
            }
          });
          setSchema(validationSchema);
        }
      });


      let enabledChanged = false;
      let enabledMap = enabledVals;
      enabledSubscriptions.map((subscribedFields: any, boundField) => {
        if (lastKnownEnabled.current[boundField] !== formik.values[boundField]) {
          lastKnownEnabled.current[boundField] = formik.values[boundField];
          Object.keys(subscribedFields).forEach(fieldId => {
            enabledMap = enabledMap.set(fieldId, formik.values[boundField]);
            if (!formik.values[boundField]) {
              // clear the value of the field
              lastKnownValues.current[fieldId] = formik.values[fieldId];
              formik.setFieldValue(fieldId, null);
            } else if (lastKnownValues.current[fieldId]) {
              formik.setFieldValue(fieldId, lastKnownValues.current[fieldId]);
            }
            enabledChanged = true;
          });
        }
      });

      if (enabledChanged) {
        setEnabledVals(enabledMap);
      }
    }
  }, [formik.values, bindings, enabledSubscriptions, requiredSubscriptions, enabledVals]);


  const handleClose = () => {
    formik.resetForm();
    props.handleClose();
  };
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const getModalStyle = () => {
    if (layout === 6 && !isMobile) {
      const width = '75%';
      return {
        width
      };
    }
    return;
  };

  const updateEnabledVals = (name, value) => {
    setEnabledVals(enabledVals.set(name, value));
  };

  const renderField = (field) => {
    const type = field.get('type');
    const fieldId = field.get('id');
    const bindField = field.get('bindField');
    const forceDisabled = field.get('disabled', false);
    const designedValue = field.get('designedValue', null);
    let bindValsObj;

    if (isImmutable(field.get('bindFieldValue'))) {
      bindValsObj = field.get('bindFieldValue').toJS();
    }

    if (!fieldComponents[type]) {
      throw new Error(`Invalid Settings Form Field Type: ${type}`);
    }

    const FieldComponent = fieldComponents[type];
    let disabled = false;
    if (readonly || forceDisabled || field.get('readonlyField', false)) {
      disabled = true;
    }
    else {
      disabled = bindField ? (!bindValsObj.includes((enabledVals.get(bindField))) ? true : false) : false;
    }
    if (field.get('enabled') && enabledVals.get(fieldId, true) !== '') {
      disabled = !enabledVals.get(fieldId, true);
    }

    fieldIsDisabledById[fieldId] = disabled;

    const handleFieldChange = (fieldId, value) => {
      formik.setFieldValue(fieldId, value);
      handleChange(fieldId, value); // passed up to parent to modify form fields if necessary
      updateEnabledVals(fieldId, value); // used to control fields that are enabled disabled dependind a bound field's value
    };
    if (locked) {
      field = field.set('label',
        <Tooltip title={'Talk to your system administrator to gain access to edit' }>
          <div>
            {field.get('label')}
            <span style={{ marginLeft: '5px' }}><LockIcon fontSize='medium' /></span>
          </div>
        </Tooltip>
      );
    }
    return (
      <Grid item xs={12} md={layout || 12} key={fieldId}>
        <FieldComponent
          key={`${fieldId}`}
          field={field}
          value={formik.values[fieldId]}
          error={formik.errors[fieldId]}
          updateEnabledVals={updateEnabledVals}
          handleChange={handleFieldChange}
          disabled={disabled || locked}
          designedValue={designedValue}
        />
      </Grid>
    );
  };

  // Create a copy of fieldSetsOrder to keep track of processed keys
  const remainingKeys = Object.keys(fieldsetMap).sort();
  const content = <div className={noModal ? null : 'modal settings-modal'} style={getModalStyle()}>
    <div className='settings-modal-top-bar'>
      {title ? <h2 className='settings-modal-title'>{title}</h2> : null}
    </div>
    <div className='settings-modal-content' >
      <Grid key={'g1'} container={!noModal} spacing={noModal ? undefined : 3} style={{ display: layout ? 'flex' : 'block' }}>
        <form onSubmit={formik.handleSubmit} data-testid={`settingModalFrom`}>
          {fieldSetsOrder.map((fieldsetId) => {
            const index = remainingKeys.indexOf(fieldsetId);
            if (index !== -1) {
              remainingKeys.splice(index, 1); // Remove the key from remainingKeys
            }
            return (
              (fieldsetId !== 'none') && fieldsetMap[fieldsetId] ? (
                <fieldset key={fieldsetId}>
                  <legend>{langLookUpText(fieldsetId)}</legend>
                  <Grid key={'g2'} container spacing={3}>
                    {fieldsetMap[fieldsetId].map(field => field.get('hidden') ? '' : renderField(field))}
                  </Grid>
                </fieldset>
              ) : fieldsetMap[fieldsetId] ?
                <Grid key={'g3'} container spacing={3}>
                  {fieldsetMap[fieldsetId].map(field => field.get('hidden') ? '' : renderField(field))}
                </Grid>
                : null
            );
          })}

          {/* Render remaining keys that are in fieldsetMap but not in fieldSetsOrder */}
          {remainingKeys.map((fieldsetId, idx) => (
            (fieldsetId !== 'none') ?
              <fieldset key={fieldsetId}>
                <legend>{langLookUpText(fieldsetId)}</legend>
                <Grid key={`${fieldsetId}-${idx}-1`} container spacing={3}>
                  {fieldsetMap[fieldsetId].map(field => field.get('hidden') ? '' : renderField(field))}
                </Grid>
              </fieldset>
              : <Grid key={`${fieldsetId}-${idx}-2`} container spacing={3}>
                {fieldsetMap[fieldsetId].map(field => field.get('hidden') ? '' : renderField(field))}
              </Grid>
          ))}
          <div className='button-bar'>
            {noModal ? null : <Button onClick={handleClose}>{readonly ? 'Close' : 'Cancel'}</Button>}
            {readonly || !props.handleSubmit ? null : <Button cta type='submit' loading={loading}>Save</Button>}
          </div>
        </form>
      </Grid>
    </div>
  </div>;

  const modalContent = () => {

    return (
      <Modal
        data-testid={dataTestId ? dataTestId : 'settings-modal'}
        open={props.open}
        onClose={handleClose}
        aria-labelledby='settings-modal-title'
      >
        {content}
      </Modal>
    );
  };
  if ((!noModal && !props.open) || title === '') {
    return <></>;
  }

  return (<div>{noModal ? content : modalContent()}</div>);
}
const SettingsModal = React.memo(SettingsModalMemo, (prevProps, nextProps) => {
  const simpleChecks = ['open', 'title', 'loading', 'id', 'dataTestId', 'noModal'];
  const noDiffSimple = simpleChecks.every(propName => {
    if (prevProps[propName] !== nextProps[propName]) {
      return false;
    } else {
      return true;
    }
  });
  if (!noDiffSimple) {
    return false;
  }
  const dataChecks = ['fieldSetsOrder', 'fields', 'settings'];
  const noDiff = dataChecks.every(propName => {
    if (prevProps[propName].equals && !prevProps[propName].equals(nextProps[propName])) {
      return false;
    }

    return true;
  });

  return noDiff;

});
export default SettingsModal;
SettingsModalMemo.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
  open: PropTypes.bool,
  settings: ImmutablePropTypes.map,
  fields: ImmutablePropTypes.map.isRequired,
  title: PropTypes.string.isRequired,
  layout: PropTypes.number,
  noModal: PropTypes.bool,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string,
  fieldSetsOrder: PropTypes.oneOfType([
    ImmutablePropTypes.list,
    PropTypes.array
  ]),
  dataTestId: PropTypes.string,
  locked: PropTypes.bool
};

SettingsModalMemo.defaultProps = {
  settings: Map(),
  layout: null,
  handleChange: () => void (0),
  handleClose: () => void (0),
  open: false,
  title: '',
  noModal: false,
  readonly: false,
  locked: false,
  loading: false,
  id: null,
  fieldSetsOrder: []
};
