import React from 'react';
import { Select, MenuItem, Input } from '@mui/material';

import PropTypes from 'prop-types';
import { getLabelForDeviceModel, langLookUpText } from 'src/utils/langLookUp';

export default function Assignment (props) {
  const {
    baseSettings,
    inputType,
    disabled,
    onValueUpdated,
    initialValue
  } = props;
  const [inputValue, setInputValue] = React.useState(initialValue ?? '');
  const [selectValue, setSelectValue] = React.useState(initialValue ?? 'null');

  const updateInputValue = (event) => {
    // update path value here
    setInputValue(event.target.value);
    onValueUpdated(event.target.value);
  };

  const updateSelectValue = (event) => {
    // update path value here
    setSelectValue(event.target.value);
    onValueUpdated(event.target.value);
  };

  return (
    <div style={{ display: 'flex' }}>
      {inputType === 'input' ?
        <Input
          id='input'
          type='text'
          onChange={updateInputValue}
          value={inputValue}
          fullWidth
          size="small"
          margin="none"
          style={{ width: '500px', marginTop: '22px', height: '20px' }}
          disabled={disabled}
        />
        : null
      }
      {inputType === 'select' ?
        <Select
          value={selectValue}
          variant="standard"
          disabled={disabled}
          onChange={updateSelectValue}
          style={{ width: '500px', height: 'fit-content', marginTop: '15px' }} >
          <MenuItem key={'test'} value={'null'}>{'Select a Setting'}</MenuItem>
          {
            baseSettings.map((setting) =>
              <MenuItem key={`${setting.deviceModel}-${setting.setting}`} value={`${setting.deviceModel}.${setting.setting}`}>{`${getLabelForDeviceModel(setting.deviceModel)} ${langLookUpText(setting.setting)}`}</MenuItem>
            )
          }
        </Select>
        : null
      }
    </div>
  );
}

Assignment.propTypes = {
  baseDeviceModel: PropTypes.string,
  upgradeDeviceModel: PropTypes.string,
  upgradePathId: PropTypes.string,
  inputType: PropTypes.string,
  baseSettings: PropTypes.any,
  disabled: PropTypes.bool,
  onValueUpdated: PropTypes.func.isRequired,
  initialValue: PropTypes.any,
};

Assignment.defaultProps = {
  inputType: 'input',
};
