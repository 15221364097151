import './SettingsMapPane.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import ComparisonSelector from 'src/component/UI/ComparisonSelector';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'src/component/UI/Button';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { isNumberString } from 'class-validator';
import SelectField from 'src/component/UI/SettingsModal/SettingsModalFields/SelectField';
import AutoCompleteSelectField from 'src/component/UI/SettingsModal/SettingsModalFields/AutoCompleteSelectField';
import TextField from 'src/component/UI/SettingsModal/SettingsModalFields/TextField';
import DateField from 'src/component/UI/SettingsModal/SettingsModalFields/DateField';
import SwitchField from 'src/component/UI/SettingsModal/SettingsModalFields/SwitchField';
import BitsField from 'src/component/UI/SettingsModal/SettingsModalFields/BitsField';
import { getLabelForDeviceModel, langLookUpText } from 'src/utils/langLookUp';
import { updateDeviceUpgradeCondition } from 'src/module/upgrade/action';
import Immutable from 'immutable';

export default function UpgradeCondition (props) {
  const dispatch: any = useDispatch();
  const {
    condition,
    labelOptions,
    baseSettings,
  } = props;

  const [compare, setCompare] = React.useState(condition?.get('comparison') ?? '=');
  const [value, setValue] = React.useState(condition?.get('target_value'));
  const [conditionSetting, setConditionSetting] = React.useState(condition?.get('source_path') ?? 'null');

  const updateConditionSetting = (event) => {
    setConditionSetting(event?.target?.value);
    dispatch(updateDeviceUpgradeCondition(condition.get('id'), { source_path: event?.target?.value }));
  };

  const updateCompare = (val) => {
    setCompare(val);
    if (condition?.get('id')) {
      dispatch(updateDeviceUpgradeCondition(condition.get('id'), { comparison: val }));
    }
  };

  const isBoolString = (str) => {
    return str?.toLowerCase && (str.toLowerCase() === 'true' || str.toLowerCase() === 'false');
  };

  const validateValue = (value) => {
    if (value !== '' && !isNumberString(value?.trim()) && !isBoolString(value?.trim())) {
      return false;
    }
    return true;
  };

  const updateValue = (val) => {
    const value = val.toString();
    if (value !== condition?.get('target_value')) {
      if (!validateValue(value)) {
        return;
      }
      dispatch(updateDeviceUpgradeCondition(condition.get('id'), { target_value: value }));
    }
  };

  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const onConditionValueChanged = (fieldId, e) => { // eslint-disable-line no-unused-vars
    setValue(e);
    updateValue(e);
  };

  const fieldComponents = {
    select: SelectField,
    autoCompleteSelect: AutoCompleteSelectField,
    text: TextField,
    number: TextField,
    password: TextField,
    date: DateField,
    switch: SwitchField,
    bits: BitsField,
  };

  const typeToClassName = {
    select: 'select',
    text: 'input',
    number: 'input',
    password: 'input',
  };

  const defaultValueForFieldComponent = {
    select: '',
    autoCompleteSelect: '',
    text: '',
    number: '',
    password: '',
    date: '',
    switch: false,
    bits: 0,
  };

  const renderField = (setting) => {
    const deviceSetting = setting.split('.').pop();
    const rawField = baseSettings && baseSettings.getIn ? baseSettings.getIn(['settings', deviceSetting]) : Map();
    let field =  rawField && rawField.delete ? rawField.delete('label') : rawField;
    const type = field?.get('type') ?? 'number';
    const fieldId = deviceSetting;

    const designedValue = field?.designedValue;

    if (!fieldComponents[type]) {
      throw new Error(`Invalid Settings Form Field Type: ${type}`);
    }

    const FieldComponent = fieldComponents[type];
    const disabled = false;
    let cleanedValue = value;
    if (value) {
      cleanedValue = isNumberString(value) ? value : value.toString().toLowerCase() === 'true';
    }
    // if it's an option, convert values to strings
    const options = field?.get('options');
    if (options && options.toJS) {
      const updatedOptions = options.toJS()?.map((option) => {
        return { label: option.label, value: option.value && option.value.toString ? option.value.toString() : option.value };
      });
      field = field.set('options', Immutable.fromJS(updatedOptions));
    }
    return (
      <div className={typeToClassName[type] ?? 'input'}>
        <FieldComponent
          key={`${fieldId}`}
          field={field ?? Map({})}
          value={cleanedValue ?? defaultValueForFieldComponent[type]}
          handleChange={onConditionValueChanged}
          disabled={disabled}
          designedValue={designedValue}
          showLabel={false}
          showHelperText={false}
        />
      </div>
    );
  };


  const onDelete = () => {
    const updatedUpgradeCondition = {
      value: null
    };
    setValue(undefined);
    setConditionSetting('null');
    dispatch(updateDeviceUpgradeCondition(condition.get('id'), updatedUpgradeCondition));
  };

  return (
    <div className='upgrade-condition'>
      <Select
        value={conditionSetting}
        variant="standard"
        onChange={updateConditionSetting}
        style={{ width: '300px' }}
      >
        {[<MenuItem key={`null-0`} value={'null'}>Select a Setting</MenuItem>].concat(labelOptions.map((opt, idx) => (
          <MenuItem key={`${opt.deviceModel}-${idx}`} value={`${opt.deviceModel}.${opt.setting}`}>{`${getLabelForDeviceModel(opt.deviceModel)} - ${langLookUpText(opt.setting)}`}</MenuItem>
        )
        ))}
      </Select>
      <div className='comparison'>
        <ComparisonSelector
          orNull={condition?.or_null}
          comparison={compare ?? '='}
          handleChange={updateCompare}
          options={['=', '<', '>', '!=']}
          handleOrNullToggle={() => {}}
        />
      </div>
      {
        renderField(conditionSetting)
      }
      <div className='buttonRow'>
        <Button onClick={onDelete} icon={<DeleteIcon className='deleteIcon' />} />
      </div>
    </div>
  );
}

UpgradeCondition.propTypes = {
  condition: PropTypes.any,
  labelOptions: PropTypes.any,
  baseSettings: PropTypes.any,
};

UpgradeCondition.defaultProps = {
};
