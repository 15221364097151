import PasswordValidator from 'password-validator';
import _ from 'lodash';
import { alarmsByStatusLegendLabels } from 'src/component/AlarmsList';
import moment from 'moment';
import { isLangBand, langLookUpValidationError } from './langLookUp';
import { isTextValueAOption, isTextValueASwitch, isLangATemperature } from "src/utils/langLookUp";
import { isNumber } from 'class-validator';

export function isPasswordSecure (password) {
  const passwordSchema = new PasswordValidator();
  passwordSchema
    .is().min(8)
    .is().max(100)
    .has().lowercase()
    .has().uppercase()
    .has().digits()
    .has().symbols();

  try {
    return passwordSchema.validate(password);
  } catch (err) {
    return false;
  }
}

export function isEmailAddress (email) {
  return (/^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(email));
}

export function userAvatarUrl (user) {
  return user && user.getIn(['profile', 'avatarUrl']);
}

export function ucwords (str) {
  return (str + '')
    .replace(/^(.)|\s+(.)/g, function ($1) {
      return $1.toUpperCase();
    });
}

export function formatCircuitTag (circuitTag, controllerTag) {
  if (!controllerTag) {
    return `NA / ${circuitTag}`;
  }

  return `${controllerTag} / ${circuitTag}`;
}

export function formatName (firstName, lastName) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName && !lastName) {
    return firstName;
  } else if (!firstName && lastName) {
    return lastName;
  }

  return 'N/A';
}
export function formatBytes  (bytes, decimals = 2)  {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getFileExtension (fullpath: string) {
  return fullpath.split('.').pop();
}

export function formatDate (date) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

/**
 * Takes a standard API Error reponse object and builds a friendly
 * error message that can be displayed in the UI
 *
 * @param {object} err API Reponse Error Object
 * @param {object} msg Error summary message
 * @return {string} User friendly displayable Error Message
 */
export function createUserFriendlyErrorMessage (err: any, msg: string) {
  if (err.getIn && err.getIn(['response', 'status']) !== 500) {
    const errDescription = err.getIn(['response', 'data', 'message']);

    if (errDescription) {
      msg +=  `: ${langLookUpValidationError(errDescription)}`;
    }
  } else if (_.get(err, 'response.status') !== 500) {
    const errDescription = _.get(err, 'response.data.message');

    if (errDescription) {
      msg +=  `: ${langLookUpValidationError(errDescription)}`;
    }
  }

  return msg;
}

export function stringToJsonObject (inputString, value) {
  const parts = inputString.split('.');
  const result = {};

  let current = result;
  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i];
    if (!current[part]) {
      current[part] = {};
    }
    current = current[part];
  }
  current[parts[parts.length - 1]] = value;

  return result;
}

export function celsiusToFahrenheitConvertor (celsius) {
  if (celsius === null) {
    return null;
  }
  const fahrenheit: any = (celsius * 9/5) + 32;
  return parseFloat(fahrenheit.toFixed(1));
}

export function fahrenheitToCelsiusConvertor (fahrenheit) {
  if (fahrenheit === null) {
    return null;
  }
  const celsius: any = (fahrenheit - 32) * 5/9;
  return parseFloat(celsius.toFixed(3));
}

export function convertBand (value, tempUnit) {
  let val = Number(value);
  if (tempUnit === 'F' && val !== null) {
    // database has values in 'C' unit, so convert it here without adding/subtracting 32 number and then round it for precision
    val = val * 9 / 5;
    val = Math.round(val * 2) / 2;
    return val;
  }
  return parseFloat(val.toFixed(1));
}

export function getDisplayValueForRecipeSetting (setting, value) {
  const recipeCondition = recipeConditionOptions.find(option => option.value == setting);
  const recipeOptions = recipeCondition?.options;

  if (recipeOptions) {
    const label = recipeOptions.find(option => option.value == value)?.label;
    if (label !== undefined && label !== null) {
      return label;
    }
  }
  return value;
}

export function getDisplayLabelForRecipeSetting (setting: string) {
  const recipeConditionLabel = recipeConditionOptions.find(option => option.value == setting)?.label;
  if (recipeConditionLabel) {
    return recipeConditionLabel;
  }
  return setting;
}

export const deadLegsOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }];

export const forcedOnOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' }];

const nonfunctioningOptions = [
  { label: 'Non-Functioning', value: 'true' },
  { label: 'Functional', value: 'false' }];

export const alarmOptions = alarmsByStatusLegendLabels?.reduce((acc, value, key) => {
  acc.push({ value: key, label: value.label });
  return acc;
}, []);

export const conditionOptions = [
  { value: 'commodity', label: 'Commodity', type: 'input', conditionType: 'eht_circuit', compareOptions: ['=', '!=']  },
  { value: 'location', label: 'Location', type: 'input', conditionType: 'eht_circuit', compareOptions: ['=', '!=']  },
  { value: 'line_size', label: 'Line Size', type: 'input', conditionType: 'eht_circuit', validation: 'number' },
  { value: 'dead_legs', label: 'Dead Legs', type: 'select', options: deadLegsOptions, conditionType: 'eht_circuit', compareOptions: ['='] },
  { value: 'non_functioning', label: 'Non Functioning', type: 'select', options: nonfunctioningOptions, conditionType: 'eht_alarm', compareOptions: ['='] },
  { value: 'alarm', label: 'Alarm', type: 'select', options: alarmOptions, conditionType: 'eht_alarm', compareOptions: ['='] },
  { value: 'actual_temperature', label: 'Process Temp', type: 'input', conditionType: 'eht_alarm', validation: 'number' },
  { value: 'low', label: 'Ambient Temp', type: 'input', conditionType: 'eht_alarm', validation: 'number' }
];

export const tierOptions = [
  { value: 'beta', label: 'Beta' },
  { value: 'maintenance', label: 'Maintenance' },
  { value: 'smarttrace-monitoring', label: 'SmartTrace Monitoring' },
  { value: 'smarttrace-monitoring-plus', label: 'SmartTrace Monitoring Plus' },
  { value: 'smarttrace-management', label: 'SmartTrace Management' },
  { value: 'smarttrace-enterprise', label: 'SmartTrace Enterprise' },
  { value: 'atcom-monitoring', label: 'Atcom Plus Monitoring' },
  { value: 'atcom-monitoring-plus', label: 'Atcom Plus Monitoring Plus' },
  { value: 'atcom-management', label: 'Atcom Plus Management' },
  { value: 'atcom-enterprise', label: 'Atcom Plus Enterprise' }
];

const tempSensorsRecipeOptions = [];
for (let i=1; i<=8; i++) {
  tempSensorsRecipeOptions.push(
    { value: `temperatureSensors.${i}.lowTemperatureAlarmSetpoint`, label: `TS ${i} Low Temperature Alarm Setpoint`, type: 'input', conditionType: 'eht_alarm', validation: 'number', modificationOption: ['=', '+', '-'] },
    { value: `temperatureSensors.${i}.highTemperatureAlarmSetpoint`, label: `TS ${i} High Temperature Alarm Setpoint`, type: 'input', conditionType: 'eht_alarm', validation: 'number', modificationOption: ['=', '+', '-'] }
  );
}

export const recipeConditionOptions = [
  { value: 'controlTemperatureSetpoint', label: 'Control Temperature', type: 'input', conditionType: 'eht_circuit', validation: 'number', modificationOption: ['=', '+', '-']  },
  { value: 'lowControlTemperatureAlarmSetpoint', label: 'Low Control Temperature Alarm Setpoint', type: 'input', conditionType: 'eht_alarm', validation: 'number', modificationOption: ['=', '+', '-'] },
  { value: 'highControlTemperatureAlarmSetpoint', label: 'High Control Temperature Alarm Setpoint', type: 'input', conditionType: 'eht_alarm', validation: 'number', modificationOption: ['=', '+', '-'] },
  { value: 'lowLoadCurrentAlarmSetpoint', label: 'Low Load Current Alarm Setpoint', type: 'input', conditionType: 'eht_circuit', validation: 'number', modificationOption: ['=', '+', '-']  },
  { value: 'highLoadCurrentAlarmSetpoint', label: 'High Load Current Alarm Setpoint', type: 'input', conditionType: 'eht_circuit', validation: 'number', modificationOption: ['=', '+', '-'] },
  { value: 'groundFaultTripCurrentSetpoint', label: 'Ground Fault Trip Current Setpoint', type: 'input', conditionType: 'eht_circuit', validation: 'number', modificationOption: ['=', '+', '-'] },
  { value: 'highGroundCurrentAlarmSetpoint', label: 'High Ground Current Alarm Setpoint', type: 'input', conditionType: 'eht_alarm', validation: 'number', modificationOption: ['=', '+', '-'] },
  { value: 'forcedOn', label: 'Forced On', defaultVal: '0', type: 'select', options: forcedOnOptions, conditionType: 'eht_circuit', modificationOption: ['='] },
  ...tempSensorsRecipeOptions
];

export const comparingText = {
  '=': 'is equal to',
  '<': 'is less than',
  '<=': 'is less than or equal to',
  '>': 'is greater than',
  '>=': 'is greater than or equal to',
  '!=': 'is not equal to'
};

export function addSelectedFiltersToQuery (selectedFilters, query) {
  if (!selectedFilters) {
    return query;
  }

  if (selectedFilters.get('unit')) {
    query.unit = selectedFilters.get('unit');
  }

  if (selectedFilters.get('system')) {
    query.system = selectedFilters.get('system');
  }

  if (selectedFilters.get('commodity')) {
    query.commodity = selectedFilters.get('commodity');
  }

  if (selectedFilters.get('location')) {
    query.location = selectedFilters.get('location');
  }

  if (selectedFilters.get('priority')) {
    query.priority = selectedFilters.get('priority');
  }

  if (selectedFilters.get('controller')) {
    query.controllerId = selectedFilters.get('controller');
  }

  if (selectedFilters.get('deadleg')) {
    query.deadleg = selectedFilters.get('deadleg') === 'Yes';
  }
  if (selectedFilters.get('deviceGroup')) {
    query.deviceGroup = selectedFilters.get('deviceGroup');
  }

  return query;
}

export function addOptionalFilters (optionalFilters, query) {
  if (!optionalFilters) {
    return query;
  }

  if (optionalFilters.get('controllerId')) {
    query.controllerId = optionalFilters.get('controllerId');
  }
  if (optionalFilters.get('panelId')) {
    query.panelId = optionalFilters.get('panelId');
  }
  if (optionalFilters.get('circuitId')) {
    query.circuitId = optionalFilters.get('circuitId');
  }
  if (optionalFilters.get('deviceId')) {
    query.deviceId = optionalFilters.get('deviceId');
  }
  if (optionalFilters.get('batchId')) {
    query.batchId = optionalFilters.get('batchId');
  }
  if (optionalFilters.get('userSearchInput')) {
    query.filter = optionalFilters.get('userSearchInput');
  }
  if (optionalFilters.get('status')) {
    query.status = optionalFilters.get('status');
  }
  if (optionalFilters.get('sort')) {
    query.sort = optionalFilters.get('sort');
  }
  if (optionalFilters.get('noAlarmHasNotification')) {
    query.noAlarmHasNotification = optionalFilters.get('noAlarmHasNotification');
  }
  if (optionalFilters.get('withAlarmSummary')) {
    query.withAlarmSummary = optionalFilters.get('withAlarmSummary');
  }
  if (optionalFilters.get('deviceFilters')) {
    query.deviceFilters = optionalFilters.get('deviceFilters');
  }
  if (optionalFilters.get('filter')) {
    query.filter = optionalFilters.get('filter');
  }

  return query;
}

export const isWhitelabel = () => {
  return window.location.href.includes('atcom.plus');
};

export const formatNumberByCommas = (number) => {
  const numberWithCommas = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numberWithCommas;
};

export const formatDiscrepancyValue = (setting, valueFromSetting, deviceConfigFields, temperatureUnit) => {
  const isTextAOption = isTextValueAOption(setting);
  const isSwitch = isTextValueASwitch(setting);
  const isTemp = isLangATemperature(setting);
  const isPartialTemp = isLangBand(setting);

  let value = temperatureUnit === 'F' && isTemp && valueFromSetting !== 'null' ? celsiusToFahrenheitConvertor(valueFromSetting) : valueFromSetting;
  // some setting are dropdowns and we need to find their correct label
  if (isTextAOption) {
    const options = deviceConfigFields.getIn(['settings', setting?.split('.').join(''), 'options']);
    value = options?.find(x => x.get('value').toString() === value.toString())?.get('label') || 'Invalid value';
  } else if (isPartialTemp) {
    value = convertBand(value, temperatureUnit );
  } else if (isSwitch) {
    value = value ? 'On' : 'Off';
  } else if (isNumber(value)) {
    value = formatNumberByCommas(value);
  }
  return value;
};
