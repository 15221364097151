import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { InputLabel, TextField as MuiTextField, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

export default function TextField (props) {
  const {
    field,
    value,
    error,
    handleChange,
    disabled,
    designedValue,
    showLabel,
    showHelperText,
  } = props;
  const fieldId = field.get('id');
  const type = field.get('type') === 'number' ? 'text' : field.get('type');

  const unit = field.get('unit', null);
  const label = (<>
    {field.get('label') ?? null}
    {unit ? (<>&nbsp;<span className='unit'>({unit})</span></>) : null}
  </>);

  return <div>
    {
      showLabel ?
        <InputLabel id={`text-field-${fieldId}-label`} className='text-field-label' data-testid={`text-field-${fieldId}-label`} error={Boolean(error)} disabled={disabled}>
          {label}
        </InputLabel>
        : null
    }

    <MuiTextField
      data-testid={`text-field-${fieldId}`}
      fullWidth
      key={`text-field-${fieldId}`}
      id={`text-field-${fieldId}`}
      name={`text-field-${fieldId}`}
      type={type}
      value={value !== null ? value : ''}
      onChange={(e) => handleChange(fieldId, e.target.value)}
      error={Boolean(error)}
      disabled={disabled}
      helperText={
        showHelperText ?
          <span data-testid={`text-field-helper-text-${fieldId}`}>
            {(error) ?
              <>
                {error}<br />
              </> : null}
            {field.get('helperText')}
          </span> : null
      }
      variant='outlined'
      InputLabelProps={{ shrink: false }}
      InputProps={{
        endAdornment: (
          <>
            {designedValue ?
              <Tooltip
                title={`Designed value is ${designedValue}`}
                enterTouchDelay={0}>
                <ErrorIcon style={{ fontSize: 20, cursor: 'pointer', color: '#fcd54c' }} />
              </Tooltip>
              : null}
          </>
        ),
      }}
      inputProps={{ 'aria-label': field.get('label') }}
    />
  </div>;


}

TextField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  designedValue: PropTypes.number,
  showLabel: PropTypes.bool,
  showHelperText: PropTypes.bool,
};

TextField.defaultProps = {
  disabled: false,
  designedValue: null,
  showLabel: true,
  showHelperText: true,
};

TextField.whyDidYouRender = true;
