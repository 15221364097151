import { langLookUpText, isTextValueAOption, isTextValueASwitch } from 'src/utils/langLookUp';
import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';
import { List, Map, isImmutable } from 'immutable';

export const PREFIX = '@@fox/dashboard/';

export const { fetchDevices, FETCH_DEVICES }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES`,
  ['deviceTag', yup.string().nullable()],
  ['circuitTag', yup.string().nullable()]
);

export const { fetchDevicesSettingsDiff, FETCH_DEVICES_SETTINGS_DIFF }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_SETTINGS_DIFF`,
  ['deviceId', yup.string().nullable()],
);

export const { fetchDevicesSettingsDiffSuccess, FETCH_DEVICES_SETTINGS_DIFF_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_SETTINGS_DIFF_SUCCESS`,
  ['deviceId', yup.string().nullable()],
  ['deviceSettingsDiff', yup.immutable().list().nullable()]
);

export const { fetchDevicesSuccess, FETCH_DEVICES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_SUCCESS`,
  ['device', yup.immutable().map().nullable()],
  ['circuit', yup.immutable().map().nullable()],
  ['circuits', yup.immutable().list().nullable()],
  ['deviceId', yup.string().nullable()],
  ['deviceSettingsDiff', yup.immutable().list().nullable()]
);

export const { deleteAllCircuitsUnderController, DELETE_ALL_CIRCUITS_UNDER_CONTROLLER }: any = createTypedAction(
  `${PREFIX}DELETE_ALL_CIRCUITS_UNDER_CONTROLLER`,
  ['deviceId', yup.string().nullable()],
);

export const { fetchDevicesFailed, FETCH_DEVICES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDeviceAlarms, FETCH_DEVICE_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_ALARMS`,
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesWithAlarms, FETCH_DEVICES_WITH_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_WITH_ALARMS`,
  ['deviceTag', yup.string().nullable()],
  ['childTag', yup.string().nullable()]
);

export const { fetchDevicesWithAlarmsSuccess, FETCH_DEVICES_WITH_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_WITH_ALARMS_SUCCESS`,
  ['parentDevice', yup.immutable().map().nullable()],
  ['circuit', yup.immutable().map().nullable()],
  ['childDevices', yup.immutable().list().nullable()],
  ['deviceSettingsDiff', yup.immutable().list().nullable()],
);

export const { fetchDevicesWithAlarmsFailed, FETCH_DEVICES_WITH_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_WITH_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
export const { fetchDevicesProgrammingDiscrepancies, FETCH_DEVICES_PROGRAMMING_DISCREPANCIES }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_PROGRAMMING_DISCREPANCIES`,
  ['selectedFilters', yup.object().nullable()],
  ['paginationDetails', yup.object().nullable()],
  ['selectedSetting', yup.string()],
);

export const { fetchDevicesProgrammingDiscrepanciesSuccess, FETCH_DEVICES_PROGRAMMING_DISCREPANCIES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_PROGRAMMING_DISCREPANCIES_SUCCESS`,
  ['deviceProgrammingDiscrepancies', yup.immutable().map().required()]
);

export const { fetchDevicesProgrammingDiscrepanciesFailed, FETCH_DEVICES_PROGRAMMING_DISCREPANCIES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_PROGRAMMING_DISCREPANCIES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDevice, FETCH_DEVICE }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE`,
  ['deviceId', yup.string().required()]
);

export const { fetchDeviceSuccess, FETCH_DEVICE_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SUCCESS`,
  ['device', yup.immutable().map().required()]
);

export const { fetchDeviceFailed, FETCH_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDeviceInfo, FETCH_DEVICE_INFO }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_INFO`,
  ['deviceId', yup.string().required()]
);

export const { fetchDeviceInfoSuccess, FETCH_DEVICE_INFO_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_INFO_SUCCESS`,
  ['device', yup.immutable().map().required()]
);

export const { fetchDeviceInfoFailed, FETCH_DEVICE_INFO_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_INFO_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().required()]
);

export const { fetchDeviceTree, FETCH_DEVICE_TREE }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_TREE`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
);

export const { fetchDeviceTreeSuccess, FETCH_DEVICE_TREE_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_TREE_SUCCESS`,
  ['deviceTree', yup.immutable().map().required()]
);

export const { fetchDeviceTreeFailed, FETCH_DEVICE_TREE_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_TREE_FAILED`
);

export const { fetchSiblingsInfo, FETCH_SIBLINGS_INFO }: any = createTypedAction(
  `${PREFIX}FETCH_SIBLINGS_INFO`,
  ['deviceId', yup.string().required()]
);

export const { fetchSiblingsInfoSuccess, FETCH_SIBLINGS_INFO_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_SIBLINGS_INFO_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['siblingsInfo', yup.immutable().list().required()]
);

export const { fetchSiblingsInfoFailed, FETCH_SIBLINGS_INFO_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_SIBLINGS_INFO_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDeviceSettings, FETCH_DEVICE_SETTINGS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SETTINGS`,
  ['deviceId', yup.string().required()]
);

export const { fetchDeviceSettingsSuccess, FETCH_DEVICE_SETTINGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SETTINGS_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['deviceSettings', yup.immutable().map().required()]
);

export const { fetchDeviceSettingsFailed, FETCH_DEVICE_SETTINGS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SETTINGS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().required()]
);

export const { fetchEnumeratedDeviceSettings, FETCH_ENUMERATED_DEVICE_SETTINGS }: any = createTypedAction(
  `${PREFIX}FETCH_ENUMERATED_DEVICE_SETTINGS`
);

export const { fetchEnumeratedDeviceSettingsSuccess, FETCH_ENUMERATED_DEVICE_SETTINGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_ENUMERATED_DEVICE_SETTINGS_SUCCESS`,
  ['settings', yup.immutable().map().required()]
);

export const { fetchEnumeratedDeviceSettingsFailed, FETCH_ENUMERATED_DEVICE_SETTINGS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_ENUMERATED_DEVICE_SETTINGS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { snapshotDeviceSettings, SNAPSHOT_DEVICE_SETTINGS }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_DEVICE_SETTINGS`,
  ['deviceId', yup.string().required()]
);

export const { snapshotDeviceSettingsSuccess, SNAPSHOT_DEVICE_SETTINGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_DEVICE_SETTINGS_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['snapshotStatus', yup.immutable().map().required()]
);

export const { snapshotDeviceSettingsFailed, SNAPSHOT_DEVICE_SETTINGS_FAILED }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_DEVICE_SETTINGS_FAILED`,
  ['deviceId', yup.string().required()]
);

export const { snapshotDeviceSettingsComplete, SNAPSHOT_DEVICE_SETTINGS_COMPLETE }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_DEVICE_SETTINGS_COMPLETE`,
  ['deviceId', yup.string().required()],
  ['snapshotStatus', yup.immutable().map().required()]
);

export const { snapshotSettingsSuccess, SNAPSHOT_SETTINGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_SETTINGS_SUCCESS`,
  ['deviceId', yup.string().required()]
);
export const { snapshotSettingsWarning, SNAPSHOT_SETTINGS_WARNING }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_SETTINGS_WARNING`,
  ['deviceId', yup.string().required()]
);
export const { snapshotSettingsFailed, SNAPSHOT_SETTINGS_FAILED }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_SETTINGS_FAILED`,
  ['deviceId', yup.string().required()]
);

export const { snapshotSettings, SNAPSHOT_SETTINGS }: any = createTypedAction(
  `${PREFIX}SNAPSHOT_SETTINGS`,
  ['deviceId', yup.string().required()],
);

export const { upgradeDevice, UPGRADE_DEVICE }: any = createTypedAction(
  `${PREFIX}UPGRADE_DEVICE`,
  ['deviceId', yup.string().required()],
  ['upgradeDeviceFactory', yup.string().required()],
  ['commLoopId', yup.string().required()],
  ['address', yup.string().required()],
);

export const { upgradeDeviceSuccess, UPGRADE_DEVICE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPGRADE_DEVICE_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['upgradeStatus', yup.immutable().map().required()]
);

export const { upgradeDeviceFailed, UPGRADE_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}UPGRADE_DEVICE_FAILED`,
  ['deviceId', yup.string().required()]
);

export const { clearDeviceSettingsSnapshot, CLEAR_DEVICE_SETTINGS_SNAPSHOT }: any = createTypedAction(
  `${PREFIX}CLEAR_DEVICE_SETTINGS_SNAPSHOT`
);

export const { setSettingsModalOpen, SET_SETTINGS_MODAL_OPEN }: any = createTypedAction(
  `${PREFIX}SET_SETTINGS_MODAL_OPEN`,
  ['deviceId', yup.string().nullable()],
  ['id', yup.number().required()]
);

export const { setSettingsModalClose, SET_SETTINGS_MODAL_CLOSE }: any = createTypedAction(
  `${PREFIX}SET_SETTINGS_MODAL_CLOSE`,
  ['deviceId', yup.string().nullable()],
);

export const { loadingSettingsModal, LOADING_SETTINGS_MODAL }: any = createTypedAction(
  `${PREFIX}LOADING_SETTINGS_MODAL`,
  ['deviceId', yup.string().nullable()]
);

export const { stopLoadingSettingsModal, STOP_LOADING_SETTINGS_MODAL }: any = createTypedAction(
  `${PREFIX}STOP_LOADING_SETTINGS_MODAL`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceStatus, FETCH_DEVICE_STATUS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_STATUS`,
  ['deviceId', yup.string().required()]
);

export const { fetchDeviceStatusSuccess, FETCH_DEVICE_STATUS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_STATUS_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['deviceStatus', yup.immutable().map().required()]
);

export const { fetchDeviceStatusFailed, FETCH_DEVICE_STATUS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_STATUS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().required()]
);

export const { fetchDeviceTags, FETCH_DEVICE_TAGS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_TAGS`,
  ['filter', yup.string().nullable()],
  ['deviceType', yup.string().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceTagsSuccess, FETCH_DEVICE_TAGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_TAGS_SUCCESS`,
  ['deviceTags', yup.immutable().list().required()]
);

export const { fetchDeviceTagsFailed, FETCH_DEVICE_TAGS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_TAGS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { clearDeviceTags, CLEAR_DEVICE_TAGS }: any = createTypedAction(
  `${PREFIX}CLEAR_DEVICE_TAGS`
);

export const { fetchControlPanelTags, FETCH_CONTROL_PANEL_TAGS }: any = createTypedAction(
  `${PREFIX}FETCH_CONTROL_PANEL_TAGS`,
  ['filter', yup.string().nullable()]
);

export const { fetchControlPanelTagsSuccess, FETCH_CONTROL_PANEL_TAGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CONTROL_PANEL_TAGS_SUCCESS`,
  ['controlPanelTags', yup.immutable().list().required()]
);

export const { fetchControlPanelTagsFailed, FETCH_CONTROL_PANEL_TAGS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CONTROL_PANEL_TAGS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { clearControlPanelTags, CLEAR_CONTROL_PANEL_TAGS }: any = createTypedAction(
  `${PREFIX}CLEAR_CONTROL_PANEL_TAGS`
);

export const { fetchCommLoopTags, FETCH_COMM_LOOP_TAGS }: any = createTypedAction(
  `${PREFIX}FETCH_COMM_LOOP_TAGS`,
);

export const { fetchCommLoopTagsSuccess, FETCH_COMM_LOOP_TAGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_COMM_LOOP_TAGS_SUCCESS`,
  ['commLoopTags', yup.immutable().list().required()]
);

export const { fetchCommLoopTagsFailed, FETCH_COMM_LOOP_TAGS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_COMM_LOOP_TAGS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { replaceCircuitCard, REPLACE_CIRCUIT_CARD }: any = createTypedAction(
  `${PREFIX}REPLACE_CIRCUIT_CARD`,
  ['deviceId', yup.string().required()],
  ['newAddress', yup.string().required()]
);

export const { replaceCircuitCardSuccess, REPLACE_CIRCUIT_CARD_SUCCESS }: any = createTypedAction(
  `${PREFIX}REPLACE_CIRCUIT_CARD_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['device', yup.immutable().map().required()]
);

export const { updateDeviceDesignedSettings, UPDATE_DEVICE_DESIGNED_SETTINGS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_DESIGNED_SETTINGS`,
  ['deviceId', yup.string().required()]
);

export const { updateDeviceDesignedSettingsSuccess, UPDATE_DEVICE_DESIGNED_SETTINGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_DESIGNED_SETTINGS_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['designedValues', yup.immutable().map().required()]
);

export const { updateDeviceDesignedSettingsFailed, UPDATE_DEVICE_DESIGNED_SETTINGS_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_DESIGNED_SETTINGS_FAILED`,
  ['deviceId', yup.string().required()]
);

export const { updateDevice, UPDATE_DEVICE }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE`,
  ['deviceId', yup.string().required()],
  ['deviceConfig', yup.immutable().map().required()],
  ['settingsOnly', yup.boolean()]
);

export const { updateDeviceSuccess, UPDATE_DEVICE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_SUCCESS`,
  ['device', yup.immutable().map().required()]
);

export const { updateDeviceFailed, UPDATE_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().required()]
);

export const { addDeviceFailed, ADD_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_FAILED`,
  ['deviceId', yup.string().required()]
);
export const { syncDeviceFailed, SYNC_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}SYNC_DEVICE_FAILED`,
);

export const { addDeviceFailedCleanUp, ADD_DEVICE_FAILED_CLEAN_UP }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_FAILED_CLEAN_UP`,
  ['deviceId', yup.string().required()]
);
export const { updateDeviceSettings, UPDATE_DEVICE_SETTINGS, updateDeviceSettingsQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_SETTINGS`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.deviceId, 'record', 'info', 'tag']);
    const controllerTag = devices?.getIn([state.deviceId, 'record', 'info', 'controller_tag']);
    const config = isImmutable(state.deviceConfig.get('settings', Map())) ? state.deviceConfig.get('settings', Map())?.toJS() : state.deviceConfig.get('settings', {});
    const settings = Object.entries(config).reduce((acc, [k, v]) => {
      let value = v;
      if (isTextValueAOption(k)) {
        const options = devices?.getIn([state.deviceId, 'record', 'deviceConfigFields', 'settings', k, 'options'], List());
        const option = options.find(opt => opt.get('value') === v);
        if (option) {
          value = option.get('label');
        }
      }
      else if (isTextValueASwitch(k)) {
        value = value ? "true" : 'false';
      }
      const formattedSetting = `${langLookUpText(k)} ➔ ${value}`;
      acc.push(formattedSetting);
      return acc;
    }, []);
    // Join settings into a formatted string
    const formattedSettings = settings.join(', ');
    return `Update ${controllerTag}/${deviceTag} ${formattedSettings}`;
  },
  ['deviceId', yup.string().required()],
  ['deviceConfig', yup.immutable().map().required()],
  ['fetchSettingsAfterUpdate', yup.boolean()]
);

export const { updateDeviceSettingsSuccess, UPDATE_DEVICE_SETTINGS_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_SETTINGS_SUCCESS`,
  ['device', yup.immutable().map().required()]
);

export const { updateDeviceSettingsFailed, UPDATE_DEVICE_SETTINGS_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_SETTINGS_FAILED`,
  ['deviceId', yup.string().required()],
  ['error', yup.string().required()],
  ['messageId', yup.string().required()]
);

export const { fetchStatistics, FETCH_STATISTICS }: any = createTypedAction(
  `${PREFIX}FETCH_STATISTICS`,
  ['deviceId', yup.string().required()]
);

export const { fetchStatisticsSuccess, FETCH_STATISTICS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_STATISTICS_SUCCESS`,
  ['statistics', yup.immutable().map().required()],
  ['deviceId', yup.string().required()]
);

export const { fetchStatisticsFailed, FETCH_STATISTICS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_STATISTICS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchExport, FETCH_EXPORT }: any = createTypedAction(
  `${PREFIX}FETCH_EXPORT`,
  ['deviceId', yup.string().required()]
);

export const { fetchExportSuccess, FETCH_EXPORT_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_EXPORT_SUCCESS`,
  ['export', yup.number().required()],
  ['deviceId', yup.string().required()]
);

export const { fetchExportFailed, FETCH_EXPORT_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_EXPORT_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesAlarms, FETCH_DEVICES_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ALARMS`,
  ['controllerId', yup.string().nullable()],
  ['circuitId', yup.string().nullable()],
  ['subAlarmsOnly', yup.bool().required()], // false
  ['minimize', yup.bool().required()], // false
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesActiveWorkTickets, FETCH_DEVICES_ACTIVE_WORK_TICKETS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_WORK_TICKETS`,
  ['includeCompleted', yup.boolean()],
  ['unit', yup.string().nullable()],
  ['page', yup.number()],
  ['pageSize', yup.number()],
  ['filter', yup.string().nullable()],
  ['sort', yup.string().nullable()],
  ['deviceId', yup.string().nullable()],
);

export const { fetchDevicesArchivedWorkTickets, FETCH_DEVICES_ARCHIVED_WORK_TICKETS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_WORK_TICKETS`,
  ['includeCompleted', yup.boolean()],
  ['unit', yup.string().nullable()],
  ['page', yup.number()],
  ['pageSize', yup.number()],
  ['filter', yup.string().nullable()],
  ['sort', yup.string().nullable()],
  ['deviceId', yup.string().nullable()],
);

export const { fetchDevicesActiveNotes, FETCH_DEVICES_ACTIVE_NOTES }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_NOTES`,
  ['note', yup.string().nullable()],
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['paginationDetails', yup.object().nullable()],
  ['includeArchived', yup.boolean().required().default(false)],
  ['deviceId', yup.string().nullable()],
);

export const { fetchDevicesArchivedNotes, FETCH_DEVICES_ARCHIVED_NOTES }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_NOTES`,
  ['note', yup.string().nullable()],
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['paginationDetails', yup.object().nullable()],
  ['includeArchived', yup.boolean().required().default(false)],
  ['deviceId', yup.string().nullable()],
);

export const { fetchDevicesActiveAlarms, FETCH_DEVICES_ACTIVE_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_ALARMS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
  ['page', yup.number()],
  ['pageSize', yup.number()]
);

export const { fetchDevicesArchivedAlarms, FETCH_DEVICES_ARCHIVED_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_ALARMS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
  ['page', yup.number()],
  ['pageSize', yup.number()]
);

export const { fetchPanelActiveAlarms, FETCH_PANEL_ACTIVE_ALARMS }: any = createTypedAction(
  `${PREFIX}FETCH_PANEL_ACTIVE_ALARMS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
  ['optionalFilters', yup.immutable().map()], // filter by component or user
  ['page', yup.number()],
  ['pageSize', yup.number()],
);

export const { fetchDevicesActiveWorkTicketsSuccess, FETCH_DEVICES_ACTIVE_WORK_TICKETS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_WORK_TICKETS_SUCCESS`,
  ['workTickets', yup.immutable().list().required()],
  ['deviceId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()]
);

export const { fetchDevicesArchivedWorkTicketsSuccess, FETCH_DEVICES_ARCHIVED_WORK_TICKETS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_WORK_TICKETS_SUCCESS`,
  ['workTickets', yup.immutable().list().required()],
  ['deviceId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()],
);

export const { fetchDevicesActiveNotesSuccess, FETCH_DEVICES_ACTIVE_NOTES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_NOTES_SUCCESS`,
  ['notes', yup.immutable().list()],
  ['deviceId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()]
);

export const { updateDeviceNoteSuccess, UPDATE_DEVICE_NOTE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_NOTE_SUCCESS`,
  ['note', yup.immutable().map().required()]
);

export const { fetchDevicesArchivedNotesSuccess, FETCH_DEVICES_ARCHIVED_NOTES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_NOTES_SUCCESS`,
  ['notes', yup.immutable().list()],
  ['deviceId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()]
);

export const { fetchDevicesActiveAlarmsSuccess, FETCH_DEVICES_ACTIVE_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_ALARMS_SUCCESS`,
  ['alarms', yup.immutable().list()],
  ['deviceId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()],
  ['isChannelInCommFail', yup.boolean()],
  ['isControllerInCommFail', yup.boolean()]
);

export const { fetchDevicesArchivedAlarmsSuccess, FETCH_DEVICES_ARCHIVED_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_ALARMS_SUCCESS`,
  ['alarms', yup.immutable().list()],
  ['deviceId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()]
);

export const { fetchPanelActiveAlarmsSuccess, FETCH_PANEL_ACTIVE_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_PANEL_ACTIVE_ALARMS_SUCCESS`,
  ['alarms', yup.immutable().list()],
  ['panelId', yup.string().nullable()],
  ['total', yup.number().required()],
  ['numPages', yup.number().required()]
);

export const { fetchDevicesActiveWorkTicketsFailed, FETCH_DEVICES_ACTIVE_WORK_TICKETS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_WORK_TICKETS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesArchivedWorkTicketsFailed, FETCH_DEVICES_ARCHIVED_WORK_TICKETS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_WORK_TICKETS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesActiveNotesFailed, FETCH_DEVICES_ACTIVE_NOTES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_NOTES_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesArchivedNotesFailed, FETCH_DEVICES_ARCHIVED_NOTES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_NOTES_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesActiveAlarmsFailed, FETCH_DEVICES_ACTIVE_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ACTIVE_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDevicesArchivedAlarmsFailed, FETCH_DEVICES_ARCHIVED_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_ARCHIVED_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchPanelActiveAlarmsFailed, FETCH_PANEL_ACTIVE_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_PANEL_ACTIVE_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['panelId', yup.string().nullable()]
);

export const { fetchDeviceConfigFields, FETCH_DEVICE_CONFIG_FIELDS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CONFIG_FIELDS`,
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceConfigFieldsSuccess, FETCH_DEVICE_CONFIG_FIELDS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CONFIG_FIELDS_SUCCESS`,
  ['deviceConfigFields', yup.immutable().map().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceConfigFieldsFailed, FETCH_DEVICE_CONFIG_FIELDS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CONFIG_FIELDS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDeviceConfigFieldsGeneric, FETCH_DEVICE_CONFIG_FIELDS_GENERIC }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CONFIG_FIELDS_GENERIC`,
  ['models', yup.array().required()]
);

export const { fetchDeviceConfigFieldsGenericSuccess, FETCH_DEVICE_CONFIG_FIELDS_GENERIC_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CONFIG_FIELDS_GENERIC_SUCCESS`,
  ['deviceConfigFieldsGeneric', yup.immutable().map().nullable()],
  ['models', yup.array().required()]
);

export const { fetchDeviceConfigFieldsGenericFailed, FETCH_DEVICE_CONFIG_FIELDS_GENERIC_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CONFIG_FIELDS_GENERIC_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updatePanel, UPDATE_PANEL, updatePanelQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_PANEL`,
  (state, devices, alarms, panels) => {
    const otherPanels = panels.find(x => x.get('control_panel_id') === state.panel.id);
    const currentPanel = panels.find(x => x.get('control_panel_id') === null);

    const tags = state.panel.children.map(x => otherPanels.get('children').find(y => y.get('id') === x).get('tag') || currentPanel.get('children').find(y => y.get('id') === x).get('tag'));
    return `update panel tag to ${state.panel.tag} and panel controllers:  ${tags}`;
  },
  ['panel', yup.immutable().map().required()]
);

export const { updatePanelSuccess, UPDATE_PANEL_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_PANEL_SUCCESS`,
  ['panel', yup.immutable().map().required()]
);

export const { updatePanelFailed, UPDATE_PANEL_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_PANEL_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deletePanel, DELETE_PANEL, deletePanelQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_PANEL`,
  (state, devices, alarms, panels) => {
    const tag = panels.find(x => x.get('control_panel_id') === state.panel_id).get('panel_tag');

    return `delete ${tag} panel`;
  },
  ['panel_id', yup.immutable().map().required()]
);

export const { deletePanelSuccess, DELETE_PANEL_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_PANEL_SUCCESS`,
  ['panel_id', yup.immutable().map().required()]
);

export const { deletePanelFailed, DELETE_PANEL_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_PANEL_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteChildDevice, DELETE_CHILD_DEVICE, deleteChildDeviceQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_CHILD_DEVICE`,
  (state, devices) => {
    const child = devices?.getIn([state.childId, 'record', 'info']);
    const device = devices?.getIn([state.deviceId, 'record', 'info']);
    return `remove ${child.get('type')} ${child.get('tag')} from ${device.get('type')} ${device.get('tag')}`;
  },
  ['childId', yup.string().required()],
  ['deviceId', yup.string().required()]
);

export const { deleteChildDeviceSuccess, DELETE_CHILD_DEVICE_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_CHILD_DEVICE_SUCCESS`,
  ['childId', yup.string().required()],
  ['deviceId', yup.string().required()]
);

export const { deleteChildDeviceFailed, DELETE_CHILD_DEVICE_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_CHILD_DEVICE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { loadDevicesFromCircuitsList, LOAD_DEVICES_FROM_CIRCUITS_LIST }: any = createTypedAction(
  `${PREFIX}LOAD_DEVICES_FROM_CIRCUITS_LIST`,
  ['devices', yup.immutable().list().nullable()],
  ['alarmSummary', yup.immutable().map().nullable()]
);

export const { deleteDevice, DELETE_DEVICE }: any = createTypedAction(
  `${PREFIX}DELETE_DEVICE`,
  ['deviceId', yup.string().nullable()]
);

// Dashboard stats like total kWh
export const { fetchDashboardStats, FETCH_DASHBOARD_STATS }: any = createTypedAction(
  `${PREFIX}FETCH_DASHBOARD_STATS`,
  ['selectedFilters', yup.immutable().map()], // filters from top bar
);

export const { fetchDashboardStatsSuccess, FETCH_DASHBOARD_STATS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DASHBOARD_STATS_SUCCESS`,
  ['stats', yup.immutable().map().nullable()],
);

export const { fetchDashboardStatsFailed, FETCH_DASHBOARD_STATS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DASHBOARD_STATS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteCommLoop, DELETE_COMM_LOOP }: any = createTypedAction(
  `${PREFIX}DELETE_COMM_LOOP`,
  ['deviceId', yup.string().required()]
);
export const { deleteCommLoopSuccess, DELETE_COMM_LOOP_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_COMM_LOOP_SUCCESS`,
  ['deviceId', yup.string().required()]
);

export const { dismissDeviceAlarms, DISMISS_DEVICE_ALARMS, dismissDeviceAlarmsQueueFormatter }: any = createTypedAction(
  `${PREFIX}DISMISS_DEVICE_ALARMS`,
  (state, devices, alarms) => {
    const deviceTag = devices?.getIn([state.circuitId, 'record', 'info', 'tag']);
    const controller_id = devices?.getIn([state.circuitId, 'record', 'info', 'parent_id']);
    const controllerTag = devices?.getIn([controller_id, 'record', 'info', 'tag']);
    const alarmGroups = state.alarmIds.map(x => alarms?.getIn([x, 'record', 'alarm_group']));
    return `${state.note} ${alarmGroups} alarm on ${controllerTag}/${deviceTag}`;
  },
  ['note', yup.string()],
  ['controllerId', yup.string().nullable()],
  ['circuitId', yup.string().nullable()],
  ['alarmIds', yup.array().of(yup.string())],
  ['expiredAt', yup.string().nullable()],
  ['subject', yup.string().nullable()],
  ['alarmListFilters', yup.object().required()],
  ['pageInfo', yup.object().required()]
);
export const { dismissDeviceAlarmsSuccess, DISMISS_DEVICE_ALARMS_SUCCESS }: any = createTypedAction(
  `${PREFIX}DISMISS_DEVICE_ALARMS_SUCCESS`,
  ['alarmIds', yup.array().of(yup.string())],
  ['deviceId', yup.string().required()]
);

export const { dismissDeviceAlarmsFailed, DISMISS_DEVICE_ALARMS_FAILED }: any = createTypedAction(
  `${PREFIX}DISMISS_DEVICE_ALARMS_FAILED`,
  ['err', yup.immutable().map().nullable()],
  ['deviceId', yup.string().required()]
);

export const { uploadDeviceFile, UPLOAD_DEVICE_FILE, uploadDeviceFileQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPLOAD_DEVICE_FILE`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.deviceId, 'record', 'info', 'tag']);
    const parentId = devices?.getIn([state.deviceId, 'record', 'info', 'parent_id']);
    const parentTag = devices?.getIn([parentId, 'record', 'info', 'tag']);
    return `${JSON.stringify(state.importFile.path)} file added to ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''}`;
  },
  ['importFile', yup.object().nullable()],
  ['importType', yup.string().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { uploadDeviceFileSuccess, UPLOAD_DEVICE_FILE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPLOAD_DEVICE_FILE_SUCCESS`,
  ['importFile', yup.object()],
  ['deviceId', yup.string().nullable()],
  ['ext', yup.string().nullable()]
);

export const { uploadDeviceFileFailed, UPLOAD_DEVICE_FILE_FAILED }: any = createTypedAction(
  `${PREFIX}UPLOAD_DEVICE_FILE_FAILED`,
  ['importFile', yup.object()],
  ['deviceId', yup.string().nullable()],
);

export const { updateDeviceFileContents, UPDATE_DEVICE_FILE_CONTENTS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILE_CONTENTS`,
  ['importFile', yup.object().nullable()],
  ['importType', yup.string().nullable()],
  ['deviceId', yup.string()],
  ['uploadId', yup.string()],
  ['version', yup.string()],
);

export const { updateDeviceFileContentsSuccess, UPDATE_DEVICE_FILE_CONTENTS_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILE_CONTENTS_SUCCESS`,
  ['importFile', yup.object()],
  ['deviceId', yup.string().nullable()],
  ['updatedFile', yup.object().nullable()]
);

export const { updateDeviceFileContentsFailed, UPDATE_DEVICE_FILE_CONTENTS_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILE_CONTENTS_FAILED`,
  ['deviceId', yup.string()]
);

export const { removeDeviceFile, REMOVE_DEVICE_FILE, removeDeviceFileQueueFormatter }: any = createTypedAction(
  `${PREFIX}REMOVE_DEVICE_FILE`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.deviceId, 'record', 'info', 'tag']);
    const parentId = devices?.getIn([state.deviceId, 'record', 'info', 'parent_id']);
    const parentTag = devices?.getIn([parentId, 'record', 'info', 'tag']);
    return `${state.fileName} file removed from ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''}`;
  },
  ['fileName', yup.string().nullable()],
  ['created_at', yup.string().nullable()],
  ['deviceId', yup.string().nullable()],
  ['fileId', yup.string().nullable()],
);

export const { removeDeviceFileSuccess, REMOVE_DEVICE_FILE_SUCCESS }: any = createTypedAction(
  `${PREFIX}REMOVE_DEVICE_FILE_SUCCESS`,
  ['fileId', yup.string().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { removeDeviceFileFailed, REMOVE_DEVICE_FILE_FAILED }: any = createTypedAction(
  `${PREFIX}REMOVE_DEVICE_FILE_FAILED`,
  ['fileName', yup.string().nullable()],
  ['created_at', yup.number().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceFile, FETCH_DEVICE_FILE }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_FILE`,
  ['fileName', yup.string().nullable()],
  ['created_at', yup.string().nullable()],
  ['deviceId', yup.string().nullable()],
  ['fileId', yup.string().nullable()],
);

export const { fetchDeviceFiles, FETCH_DEVICE_FILES }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_FILES`,
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceFilesSuccess, FETCH_DEVICE_FILES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_FILES_SUCCESS`,
  ['files', yup.immutable().list().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceFilesFailed, FETCH_DEVICE_FILES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_FILES_FAILED`,
  ['deviceId', yup.string().nullable()]
);

export const { updateDeviceFilesDescription, UPDATE_DEVICE_FILES_DESCRIPTION, updateDeviceFilesDescriptionQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILES_DESCRIPTION`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.deviceId, 'record', 'info', 'tag']);
    const parentId = devices?.getIn([state.deviceId, 'record', 'info', 'parent_id']);
    const parentTag = devices?.getIn([parentId, 'record', 'info', 'tag']);
    return `${state.fileName} file description updated to '${state.description}' for  ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''} `;
  },
  ['description', yup.string().nullable()],
  ['deviceId', yup.string().nullable()],
  ['fileId', yup.string().nullable()],
);

export const { updateDeviceFilesDescriptionSuccess, UPDATE_DEVICE_FILES_DESCRIPTION_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILES_DESCRIPTION_SUCCESS`,
  ['fileId', yup.string().nullable()],
  ['description', yup.string().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { updateDeviceFilesDescriptionFailed, UPDATE_DEVICE_FILES_DESCRIPTION_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILES_DESCRIPTION_FAILED`,
  ['deviceId', yup.string().nullable()]
);

export const { updateDeviceFilesSubType, UPDATE_DEVICE_FILES_SUB_TYPE, updateDeviceFilesSubTypeQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILES_SUB_TYPE`,
  (state, devices) => {
    const deviceTag = devices?.getIn([state.deviceId, 'record', 'info', 'tag']);
    const parentId = devices?.getIn([state.deviceId, 'record', 'info', 'parent_id']);
    const parentTag = devices?.getIn([parentId, 'record', 'info', 'tag']);
    return `${state.fileName} file description updated to '${state.description}' for  ${parentTag || ''}${ parentTag ? '/' : ''}${deviceTag || ''} `;
  },
  ['subType', yup.string().nullable()],
  ['deviceId', yup.string().nullable()],
  ['fileId', yup.string().nullable()],
);

export const { updateDeviceFilesSubTypeSuccess, UPDATE_DEVICE_FILES_SUB_TYPE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILES_SUB_TYPE_SUCCESS`,
  ['fileId', yup.string().nullable()],
  ['subType', yup.string().nullable()],
  ['deviceId', yup.string().nullable()]
);

export const { updateDeviceFilesSubTypeFailed, UPDATE_DEVICE_FILES_SUB_TYPE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_FILES_SUB_TYPE_FAILED`,
  ['deviceId', yup.string().nullable()]
);

export const { fetchSensorHistory, FETCH_SENSOR_HISTORY }: any = createTypedAction(
  `${PREFIX}FETCH_SENSOR_HISTORY`,
  ['selectedFilters', yup.immutable().map().required()],
  ['timeFrame', yup.string().nullable()],
  ['rollUp', yup.string().nullable()]
);
export const { fetchSensorHistorySuccess, FETCH_SENSOR_HISTORY_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_SENSOR_HISTORY_SUCCESS`,
  ['sensorData', yup.immutable().map().required()],
  ['timeFrame', yup.string().nullable()],
  ['rollUp', yup.string().nullable()]
);

export const { fetchDeviceSensorHistory, FETCH_DEVICE_SENSOR_HISTORY }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SENSOR_HISTORY`,
  ['deviceId', yup.string().nullable()],
  ['timeFrame', yup.string().nullable()],
  ['rollUp', yup.string().nullable()]
);

export const { fetchDeviceSensorHistorySuccess, FETCH_DEVICE_SENSOR_HISTORY_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SENSOR_HISTORY_SUCCESS`,
  ['deviceId', yup.string().required()],
  ['sensorData', yup.immutable().map().required()],
  ['timeFrame', yup.string().nullable()],
  ['rollUp', yup.string().nullable()]
);

export const { fetchDeviceSensorHistoryFailed, FETCH_DEVICE_SENSOR_HISTORY_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SENSOR_HISTORY_FAILED`,
  ['deviceId', yup.string().nullable()],
);

export const { dispatchDeviceAction, DISPATCH_DEVICE_ACTION }: any = createTypedAction(
  `${PREFIX}DISPATCH_DEVICE_ACTION`,
  ['deviceId', yup.string().required()],
  ['deviceAction', yup.string().required()],
  ['deviceActionObj', yup.immutable().map()],
);

export const { syncUitDevices, SYNC_UIT_DEVICES }: any = createTypedAction(
  `${PREFIX}SYNC_UIT_DEVICES`,
  ['deviceId', yup.string().required()],
  ['deviceTag', yup.string().required()],
);

export const { dispatchDeviceActionSuccess, DISPATCH_DEVICE_ACTION_SUCCESS }: any = createTypedAction(
  `${PREFIX}DISPATCH_DEVICE_ACTION_SUCCESS`,
  ['devices', yup.immutable().list().nullable()],
);

export const { dispatchDeviceActionFailed, DISPATCH_DEVICE_ACTION_FAILED }: any = createTypedAction(
  `${PREFIX}DISPATCH_DEVICE_ACTION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchDeviceActions, FETCH_DEVICE_ACTIONS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_ACTIONS`,
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceActionsSuccess, FETCH_DEVICE_ACTIONS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_ACTIONS_SUCCESS`,
  ['deviceActions', yup.immutable().map()],
  ['deviceId', yup.string().nullable()]
);

export const { fetchDeviceActionsFailed, FETCH_DEVICE_ACTIONS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_ACTIONS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { syncDevicesSuccess, SYNC_DEVICES_SUCCESS }: any = createTypedAction(
  `${PREFIX}SYNC_DEVICES_SUCCESS`,
  ['devices', yup.immutable().list().nullable()],
);

export const { syncDevicesStageComplete, SYNC_DEVICES_STAGE_COMPLETE }: any = createTypedAction(
  `${PREFIX}SYNC_DEVICES_STAGE_COMPLETE`,
  ['stage', yup.number().nullable()],
  ['totalStages', yup.number().nullable()],
);
export const { syncDeviceInfoUpdate, SYNC_DEVICE_INFO_UPDATE }: any = createTypedAction(
  `${PREFIX}SYNC_DEVICE_INFO_UPDATE`,
  ['createdDevices', yup.immutable().list().nullable()],
  ['tags', yup.immutable().list().nullable()],
);
export const { closeSyncPopUp, CLOSE_SYNC_POP_UP }: any = createTypedAction(
  `${PREFIX}CLOSE_SYNC_POP_UP`,
);

export const { fetchCommLoops, FETCH_COMM_LOOPS }: any = createTypedAction(
  `${PREFIX}FETCH_COMM_LOOPS`,
  ['paginationDetails', yup.object().nullable()],
);

export const { fetchCommLoopsSuccess, FETCH_COMM_LOOPS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_COMM_LOOPS_SUCCESS`,
  ['commLoops', yup.immutable().map().nullable()],
);

export const { forcedOnProgrammingDiscrepancySuccess, FORCED_ON_PROGRAMMING_DISCREPANCY_SUCCESS }: any = createTypedAction(
  `${PREFIX}FORCED_ON_PROGRAMMING_DISCREPANCY_SUCCESS`,
  ['deviceId', yup.string().required()],
);

export const { acknowledgeProgrammingDiscrepancy, ACKNOWLEDGE_PROGRAMMING_DISCREPANCY }: any = createTypedAction(
  `${PREFIX}ACKNOWLEDGE_PROGRAMMING_DISCREPANCY`,
  ['discrepancyId', yup.string().required()],
  ['deviceId', yup.string().required()],
);

export const { acknowledgeProgrammingDiscrepancySuccess, ACKNOWLEDGE_PROGRAMMING_DISCREPANCY_SUCCESS }: any = createTypedAction(
  `${PREFIX}ACKNOWLEDGE_PROGRAMMING_DISCREPANCY_SUCCESS`,
  ['discrepancy', yup.immutable().map().required()],
  ['deviceId', yup.string().required()],
);

export const { acknowledgeProgrammingDiscrepancyFailed, ACKNOWLEDGE_PROGRAMMING_DISCREPANCY_FAILED }: any = createTypedAction(
  `${PREFIX}ACKNOWLEDGE_PROGRAMMING_DISCREPANCY_FAILED`,
  ['err', yup.immutable().map().nullable()]);

export const { fetchDevicesAuditTrail, FETCH_DEVICES_AUDIT_TRAIL }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_AUDIT_TRAIL`,
  ['deviceId', yup.string().nullable()],
  ['pageInfo', yup.object().required()]
);

export const { fetchDevicesAuditTrailSuccess, FETCH_DEVICES_AUDIT_TRAIL_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_AUDIT_TRAIL_SUCCESS`,
  ['deviceId', yup.string().nullable()],
  ['auditTrail', yup.immutable().list()],
);

export const { fetchDevicesAuditTrailFailed, FETCH_DEVICES_AUDIT_TRAIL_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICES_AUDIT_TRAIL_FAILED`,
  ['deviceId', yup.string().nullable()],
);

export const { addCommLoop, ADD_COMM_LOOP }: any = createTypedAction(
  `${PREFIX}ADD_COMM_LOOP`,
  ['commLoop', yup.immutable().map().required()]
);

export const { addCommLoopSuccess, ADD_COMM_LOOP_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_COMM_LOOP_SUCCESS`,
  ['commLoop', yup.immutable().map().required()]
);

export const { addCommLoopFailed, ADD_COMM_LOOP_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_COMM_LOOP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { syncDevices, SYNC_DEVICES }: any = createTypedAction(
  `${PREFIX}SYNC_DEVICES`,
  ['deviceId', yup.string().nullable()],
  ['deviceTag', yup.string().nullable()],
  ['attemptNumber', yup.number().nullable()]
);

export const { clearSyncDevicesLoading, CLEAR_SYNC_DEVICES_LOADING }: any = createTypedAction(
  `${PREFIX}CLEAR_SYNC_DEVICES_LOADING`,
  ['deviceId', yup.string().nullable()],
);

export const { fetchDeviceCheckSheets, FETCH_DEVICE_CHECK_SHEETS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CHECK_SHEETS`,
  ['checkSheetType', yup.string()],
  ['deviceId', yup.string()],
  ['paginationDetails', yup.object()]
);
export const { fetchDeviceCheckSheetsSuccess, FETCH_DEVICE_CHECK_SHEETS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CHECK_SHEETS_SUCCESS`,
  ['deviceId', yup.string()],
  ['checkSheetType', yup.string()],
  ['data', yup.immutable().map()],
);
export const { fetchDeviceCheckSheetsFailed, FETCH_DEVICE_CHECK_SHEETS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CHECK_SHEETS_FAILED`,
  ['deviceId', yup.string()],
  ['checkSheetType', yup.string()],

);

export const { fetchDeviceCheckSheetSuccess, FETCH_DEVICE_CHECK_SHEET_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_CHECK_SHEET_SUCCESS`,
  ['deviceId', yup.string()],
  ['checkSheetType', yup.string()],
  ['data', yup.immutable().map()],
);

export const { addDeviceCheckSheetSuccess, ADD_DEVICE_CHECK_SHEET_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_DEVICE_CHECK_SHEET_SUCCESS`,
  ['deviceId', yup.string()],
  ['checkSheetType', yup.string()],
  ['checkSheetId', yup.immutable().map()],
);
export const { updateDeviceCheckSheetSuccess, UPDATE_DEVICE_CHECK_SHEET_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_DEVICE_CHECK_SHEET_SUCCESS`,
  ['deviceId', yup.string()],
  ['checkSheetId', yup.immutable().map()],
);
export const { fetchHiddenCircuits, FETCH_HIDDEN_CIRCUITS }: any = createTypedAction(
  `${PREFIX}FETCH_HIDDEN_CIRCUITS`,
  ['deviceId', yup.string()],
);
export const { fetchHiddenCircuitsSuccess, FETCH_HIDDEN_CIRCUITS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_HIDDEN_CIRCUITS_SUCCESS`,
  ['deviceId', yup.string()],
  ['data', yup.array()],

);
export const { fetchHiddenCircuitsFailed, FETCH_HIDDEN_CIRCUITS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_HIDDEN_CIRCUITS_FAILED`,
  ['deviceId', yup.string()],
);

export const { unhideCircuit, UNHIDE_CIRCUIT }: any = createTypedAction(
  `${PREFIX}UNHIDE_CIRCUIT`,
  ['deviceId', yup.string()]
);

export const { clearTestingReport, CLEAR_TESTING_REPORT }: any = createTypedAction(
  `${PREFIX}CLEAR_TESTING_REPORT`,
  ['reportType', yup.string()],
);
export const { generateTestingReport, GENERATE_TESTING_REPORT }: any = createTypedAction(
  `${PREFIX}GENERATE_TESTING_REPORT`,
  ['reportType', yup.string()],
  ['selectedFilters', yup.object()],
  ['deviceId', yup.string()],
  ['startDate', yup.string()],
  ['endDate', yup.string()],
);
export const { generateTestingReportPdf, GENERATE_TESTING_REPORT_PDF }: any = createTypedAction(
  `${PREFIX}GENERATE_TESTING_REPORT_PDF`,
  ['reportType', yup.string()],
  ['selectedFilters', yup.object()],
  ['deviceId', yup.string()],
  ['startDate', yup.string()],
  ['endDate', yup.string()],
);

export const { generateTestingReportSuccess, GENERATE_TESTING_REPORT_SUCCESS }: any = createTypedAction(
  `${PREFIX}GENERATE_TESTING_REPORT_SUCCESS`,
  ['reportType', yup.string()],
  ['data', yup.object()],
);
export const { generateTestingReportFailed, GENERATE_TESTING_REPORT_FAILED }: any = createTypedAction(
  `${PREFIX}GENERATE_TESTING_REPORT_FAILED`,
  ['reportType', yup.string()],
);
