import createSelector from '../createSelectorCreator';
import { Map } from 'immutable';

export const getFilterState = (state) =>
  state.filter;

export const getSelectedUnit = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedUnit');
  }
);

export const getSelectedSystem = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedSystem');
  }
);

export const getSelectedPriority = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedPriority', null);
  }
);

export const getSelectedCommodity = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedCommodity');
  }
);

export const getSelectedLocation = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedLocation');
  }
);

export const getSelectedController = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedController');
  }
);

export const getSelectedSetting = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedSetting');
  }
);

export const getSelectedDeadLeg = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedDeadLeg', '');
  }
);
export const getSelectedDeviceGroup = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('selectedDeviceGroup', '');
  }
);
export const getUnits = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('units');
  }
);

export const getSystems = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('systems');
  }
);

export const getCommodities = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('commodities');
  }
);

export const getLocations = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('locations');
  }
);

export const getPriorities = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('priorities');
  }
);

export const getControllers = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('controllers');
  }
);
export const getDeviceGroups = createSelector(
  [getFilterState],
  (filter) => {
    return filter.get('deviceGroups');
  }
);
export const getDevicesSelectedFilters = createSelector(
  [getFilterState],
  (filter) => {
    return Map({
      controller: filter.get('selectedController')?.id,
      controllerTag: filter.get('selectedController')?.tag,
      unit: filter.get('selectedUnit', null),
      system: filter.get('selectedSystem', null),
      priority: filter.get('selectedPriority', null),
      commodity: filter.get('selectedCommodity', null),
      location: filter.get('selectedLocation', null),
      deadleg: filter.get('selectedDeadLeg', ''),
      deviceGroup: filter.get('selectedDeviceGroup'),
    });
  }
);

export const getNumberOfFiltersApplied = createSelector(
  [getFilterState],
  (filter) => {
    let tempCount = 0;

    if (filter.get('selectedPriority') !== '') {
      tempCount++;
    }
    if (filter.get('selectedSystem') !== '') {
      tempCount++;
    }
    if (filter.get('selectedCommodity') !== '') {
      tempCount++;
    }
    if (filter.get('selectedLocation') !== '') {
      tempCount++;
    }
    if (filter.get('selectedController')?.id !== undefined) {
      tempCount++;
    }
    if (filter.get('selectedDeadLeg') !== '') {
      tempCount++;
    }
    if (filter.get('selectedDeviceGroup', '') !== '') {
      tempCount++;
    }
    return tempCount;
  }
);

export const getIsAllFiltersEmpty = createSelector(
  [getFilterState],
  (filter) => {
    const isEmpty = filter.get('selectedUnit', null) == "" &&
      filter.get('selectedSystem', null) == "" &&
      filter.get('selectedPriority', null) == "" &&
      filter.get('selectedCommodity', null) == "" &&
      filter.get('selectedLocation', null) == "" &&
      filter.get('selectedDeadLeg', null) == "" &&
      typeof filter.get('selectedController', null)?.id == "undefined";

    return Boolean(isEmpty);
  }
);
