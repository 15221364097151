import './SwitchField.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, Switch, Tooltip } from '@mui/material';
import ToolTip from '../../ToolTip';
import ErrorIcon from '@mui/icons-material/Error';

export default function SwitchField (props) {
  const {
    field,
    value,
    disabled,
    handleChange,
    designedValue,
    switchFirst,
    showLabel,
    showHelperText,
  } = props;

  const fieldId = field.get('id');

  const unit = field.get('unit', null);
  const label = (<>
    {field.get('label')}
    {unit ? (<>&nbsp;<span className='unit'>({unit})</span></>) : null}
  </>);

  return <FormControl>
    <div className='switch-label-wrap'>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              key={fieldId}
              id={`switch-field-${fieldId}`}
              name={fieldId}
              checked={value}
              onChange={(e) => { handleChange(fieldId, e.target.value === 'true' ? false : true); }}
              disabled={disabled}
              value={value || ''}
              color="primary"
            />
          }
          label={
            showLabel ?
              <div className='switch-label-wrap'>
                <label data-testid={`switch-field-${fieldId}`} id={`switch-field-label-${fieldId}`} className='switch-label' key={fieldId}>
                  {label}
                </label>
                {field.get('toolTip', null) ? (<div style={{ marginTop: '-10px' }}><ToolTip helpText={field.get('toolTip', null)} ></ToolTip></div>) : null}

              </div> : null}
          labelPlacement={switchFirst ? 'end' : 'start'}
          className='switchControlMethod'
        />



      </FormGroup>
      {designedValue === 0 || designedValue ?
        <Tooltip
          title={`Designed value is ${designedValue ? 'On' : 'Off'}`}
          enterTouchDelay={0}>
          <ErrorIcon style={{ fontSize: 20, verticalAlign: 'middle', cursor: 'pointer', color: '#fcd54c' }} />
        </Tooltip>
        : null}

    </div>
    { showHelperText ? <FormHelperText>{field.get('helperText', "")}</FormHelperText> : null}
  </FormControl>;
}

SwitchField.propTypes = {
  field: ImmutablePropTypes.map,
  error: PropTypes.string,
  value: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  designedValue: PropTypes.number,
  switchFirst: PropTypes.bool,
  showLabel: PropTypes.bool,
  showHelperText: PropTypes.bool,
};

SwitchField.defaultProps = {
  disabled: false,
  designedValue: null,
  switchFirst: false,
  showLabel: true,
  showHelperText: true,
};

SwitchField.whyDidYouRender = true;
