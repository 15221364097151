import localForage from 'localforage';
import queryString from 'query-string';

interface AuthQueryParams {
  jwt?: string;
}

const ACCESS_TOKEN_KEY = 'fox:auth';
const REFRESH_TOKEN_KEY = 'fox:authRefresh';
const REFRESH_TOKEN_EXPIRY = 'fox:authExpiry';
const TEMPORARY_TOKEN_KEY = 'fox:authTemp';

export async function isTokenExpired (): Promise<boolean> {
  const dateStr: string | null = await getRefreshTokenExpiry();

  if (!dateStr) {
    // if we have no expiry, default to false (we haven't authenticated successfully yet)
    return false;
  }

  try {
    const expiryDate = new Date(dateStr);
    const currentTime = new Date();
    const thirySecondsFromNow = new Date(currentTime.getTime() + 30000);

    if (currentTime.getTime() > expiryDate.getTime() || thirySecondsFromNow.getTime() > expiryDate.getTime()) {
      return true;
    }
    return false;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Failed to parse expiry date:', error); // eslint-disable-line no-console
    }
    return true;
  }
}

export async function setRefreshTokenExpiry (expiryStr = '') {
  if (process.env.NODE_ENV !== 'production') {
    if (!expiryStr || expiryStr.length < 1) {
      console.warn('Attempting to set an empty auth expiry!'); // eslint-disable-line no-console
    }
  }
  return localForage.setItem(REFRESH_TOKEN_EXPIRY, expiryStr);
}

export async function getRefreshTokenExpiry (): Promise<string> {
  return await localForage.getItem(REFRESH_TOKEN_EXPIRY);
}

export async function clear () {
  return Promise.all([
    localForage.removeItem(ACCESS_TOKEN_KEY),
    localForage.removeItem(REFRESH_TOKEN_KEY),
    localForage.removeItem(TEMPORARY_TOKEN_KEY),
    localForage.removeItem(REFRESH_TOKEN_EXPIRY),
  ]);
}

export async function getAccessToken () {
  let urlParts: Array<string> = [];
  let queryParams: AuthQueryParams = {};

  try {
    urlParts = document.location.href.split('?');
    queryParams = queryString.parse(urlParts[1] || '');
  } catch (err) {
    console.warn('Invalid document location or query params, defaulting to assume no JWT is present in the query params'); // eslint-disable-line no-console
  }

  // use jwt from the query param if it exists
  if (queryParams.jwt) {
    return queryParams.jwt;
  }

  return await localForage.getItem(ACCESS_TOKEN_KEY);
}

export async function setAccessToken (token = '') {
  if (process.env.NODE_ENV !== 'production') {
    if (!token || token.length < 1) {
      console.warn('Attempting to set an empty auth token!'); // eslint-disable-line no-console
    }
  }

  return localForage.setItem(ACCESS_TOKEN_KEY, token);
}

export async function getRefreshToken (): Promise<string> {
  return await localForage.getItem(REFRESH_TOKEN_KEY);
}

export async function setRefreshToken (token = '') {
  if (process.env.NODE_ENV !== 'production') {
    if (!token || token.length < 1) {
      console.warn('Attempting to set an empty refresh token!'); // eslint-disable-line no-console
    }
  }

  return localForage.setItem(REFRESH_TOKEN_KEY, token);
}

export async function getTempToken () {
  return await localForage.getItem(TEMPORARY_TOKEN_KEY);
}

export async function setTempToken (token = '') {
  if (process.env.NODE_ENV !== 'production') {
    if (!token || token.length < 1) {
      console.warn('Attempting to set an empty temp token!'); // eslint-disable-line no-console
    }
  }

  return localForage.setItem(TEMPORARY_TOKEN_KEY, token);
}
export async function removeTempToken () {
  return localForage.removeItem(TEMPORARY_TOKEN_KEY);
}


export default {
  clear,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  getTempToken,
  setTempToken,
  removeTempToken
};
