import './GroupedDevicesMenu.scss';
import { TreeView, TreeItem } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Badge, Breadcrumbs, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenLinkIcon from '@mui/icons-material/OpenInNewOutlined';
import { NavLink } from 'react-router-dom';
import { getDeviceTree, getLoadingDeviceTree } from 'src/module/device/selector';
import { clearSyncDevicesLoading, deletePanel, fetchDeviceTree, setSettingsModalOpen } from 'src/module/device/action';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { getDevicesSelectedFilters } from 'src/module/filter/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import EditIcon from '@mui/icons-material/Edit';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from 'src/component/UI/Button';
import { Map } from 'immutable';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddControllerModal from 'src/component/UI/AddControllerModal';
import PermissionFence from 'src/component/PermissionFence';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';

export default function GroupedDevicesMenu (props) {
  const {
    setSelectedDevice,
    setShowCreateNewPanel,
    setSelectedPanel,
    onControllerClick,
    onPanelClick
  } = props;
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = React.useState(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const [optionsNode, setOptionsNode] = React.useState(null);
  const [panel, setPanel] = React.useState(null);
  const [controller, setController] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const [panelsCollapsed, setPanelsCollapsed] = useState(true);
  const selectedFilters = useSelector(getDevicesSelectedFilters);


  useEffect(() => {
    dispatch(clearSyncDevicesLoading(null));
  }, []);

  useEffect(() => {
    dispatch(fetchDeviceTree(selectedFilters));
  }, [dispatch, selectedFilters]);

  const deviceTree: any = useSelector(getDeviceTree) || null;
  const isLoading = useSelector(getLoadingDeviceTree);
  const setReduxAddControllerModal = () => {
    dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_CONTROLLER));
  };

  //-------- click events --------//
  const handleClickOnOptions = (event: React.MouseEvent<HTMLDivElement>, node: any) => {
    event.stopPropagation();
    setOptionsNode(node);
    setAnchorEl(event.currentTarget);
    setShowOptions(true);
  };
  const clickOnNewPanel = (event) => {
    setSelectedPanel(null);
    setSelectedDevice(null);
    setShowCreateNewPanel(true);
    setPanel(null);
    event.stopPropagation();
  };
  const clickEdit = (event) => {
    setSelectedPanel(optionsNode);
    setSelectedDevice(null);
    setShowCreateNewPanel(true);
    setShowOptions(false);
    event.stopPropagation();
  };
  const clickDelete = (event) => {
    setSelectedPanel(optionsNode);
    setShowOptions(false);
    dispatch(deletePanel(optionsNode.get('id')));
    event.stopPropagation();
  };
  const breadcrumbClick = (panel = null) => {
    setSelectedPanel(panel);
    setSelectedDevice(null);
    setPanel(panel);
    setController(null);
    setShowCreateNewPanel(false);

  };

  //-------- render helper methods --------//
  const alarm = (totalAlarms) => {
    //  icon with badge (#) with the amount of alarms
    return (
      <div className='alarm-badge' data-testid={`alarmBadge-${totalAlarms}`}>
        {totalAlarms > 0 ? <Badge badgeContent={`${totalAlarms > 99 ? '99+' : totalAlarms}`} color="primary">
          <NotificationsActiveIcon fontSize='large' className='bell' />
        </Badge> : ''}
      </div>

    );
  };

  const label = (node, alarmCount) => {
    return (
      <div className='tree-item-custom-label' >

        <span>{node.get('panel_tag') || node.get('tag') || "Others"}</span>
        {alarm(alarmCount)}
        {
          node.get('control_panel_id') !== null && (node.get('type') !== 'controller' && node.get('type') !== 'circuit') ? (
            <PermissionFence can='edit-device'>
              <div onClick={event => handleClickOnOptions(event, Map({ id: node.get('control_panel_id'), type: node.get('type'), tag: node.get('panel_tag'), children: node.get('children') }))}>
                <MoreVertIcon data-testid='more-options' />
              </div>
            </PermissionFence>
          ) : null
        }
        {
          node.get('type') === 'controller' || (node.get('type') === 'circuit') ?
            <NavLink to={ (node.get('type') === 'circuit') ? `/devices/_/${node.get('tag')}` : `/devices/${node.get('tag')}`} data-testid='device-link'> <OpenLinkIcon style={{ marginRight: '14px' }} /> </NavLink>
            : null
        }
      </div >
    );
  };

  const controllerLabel = (node, alarmCount) => (
    <div className='tree-item-custom-label' >
      <span style={{ marginLeft: '20px' }}>{node.get('tag')}</span>
      {alarm(alarmCount)}
      <NavLink to={`/devices/${node.get('tag')}`} data-testid='device-link'> <OpenLinkIcon style={{ marginRight: '14px' }} /> </NavLink>
    </div>
  );


  const isVisible = (tag, children, checkChildren) => {
    // user inputs
    const searchValueWithNoSpaces = searchValue?.trim();
    const noSearchValue = (searchValue === null || searchValueWithNoSpaces === '');

    const panelTagMatchesSearchInput = Boolean(searchValueWithNoSpaces) && tag?.toUpperCase().includes(searchValueWithNoSpaces.toUpperCase());
    const deviceTagMatchesSearchInput = checkChildren ? children?.some(child => Boolean(searchValueWithNoSpaces) && child.get('tag').toUpperCase().includes(searchValueWithNoSpaces?.toUpperCase())) : false;
    const searchMatched = noSearchValue ? false : panelTagMatchesSearchInput || deviceTagMatchesSearchInput;

    if (noSearchValue || searchMatched) {
      return true;
    }
    return false;
  };

  const formatPanelTag = (panelTag) => {
    return (!panelTag || panelTag === 'null') ? 'Others' : panelTag;
  };

  deviceTree?.set(deviceTree?.get('panels')?.filter(panel => {
    return isVisible(panel.get('panel_tag'), panel.get('children'), true);
  }));

  const deviceTreeFiltered = deviceTree?.get('loading') ? Map() : deviceTree;

  const filterChildren = (children) => {
    const filtered = children.filter(child => isVisible(child.get('tag'), [], false));
    return filtered;
  };

  const filterPanels = (panels) => {
    const filtered = panels.filter(panel => isVisible(panel.get('panel_tag'), panel.get('children'), true));
    return filtered;
  };

  const handleChange = (event, nodeId) => {
    const [id, type, tag, panelID, panelType, panelTag] = nodeId.split("~");
    if (type === 'controller' || type === 'circuit') {
      const node = Map({ id, type, tag });
      onControllerClick(node);
      setController(node);
      setPanel(Map({ id: panelID, tag: panelTag, type: panelTag }));
    } else if (panelType === 'controller' || panelType === 'circuit') {
      const node = Map({ id: panelID, tag: panelTag, type: panelType, });
      onControllerClick(node);
      setController(node);
      setPanel(Map({ id: panelID, tag: panelTag, type: panelTag }));
    } else if (panelType === 'panel') {
      const node = Map({ id: panelID, tag: panelTag, type: panelType, });
      onPanelClick(node);
      setPanel(node);
      setController(null);
    }

  };

  const renderTree = (panel, alarmCounts) => {
    const panelId = `${panel.get('control_panel_id')}-panel`;
    const noParentControllerId = panel.get('id');
    const panelIdForAlarms = panel.get('control_panel_id') ? panelId : noParentControllerId;
    return (
      <TreeItem
        key={panel.get('control_panel_id') || noParentControllerId}
        nodeId={`~~~${panel.get('control_panel_id') ?? noParentControllerId}~${panel.get('type')}~${panel.get('panel_tag') || panel.get('tag')}`}
        label={label(panel, alarmCounts.get(panelIdForAlarms))}
      >
        {
          filterChildren(panel.get('children', [])).map((device) =>
            <TreeItem
              key={device.get('id') + "childview"}
              nodeId={`${device.get('id')}~${device.get('type')}~${device.get('tag')}~${panel.get('control_panel_id') ?? noParentControllerId}~${panel.get('type')}~${panel.get('panel_tag') || panel.get('tag')}`}
              label={controllerLabel(device, alarmCounts.get(device.get('id')))}
            />
          )
        }
      </TreeItem>
    );
  };

  return (
    <div className='device-menu-container' data-testid='grouped-devices-menu'>
      {isMobile ? <h3 className='expandable' onClick={() => setPanelsCollapsed(!panelsCollapsed)}>Panels &nbsp;<span className='expand-arrow'>{panelsCollapsed ? '▼' : '▲'}</span></h3> : null}
      {
        (isLoading && !isMobile) || (isLoading && isMobile && !panelsCollapsed) ? <div className='loading'><LogoSpinner size={100} /></div> :
          ((!panelsCollapsed && isMobile) || !isMobile) ? <>
            <div className='device-menu-search'>
              {/* <div className='loading'><LogoSpinner size={100} /></div> */}

              <TextField
                data-testid={`text-field-panel-search`}
                id={`text-field-panel-search`}
                placeholder={'Search tags'}
                variant='standard'
                margin='dense'
                value={searchValue || ''}
                className={'search-field'}
                sx={{ width: '30vw' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
            <div className='breadcrumb-contrainer'>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb">
                <Typography className='breadCrumbs' onClick={() => breadcrumbClick()}>All</Typography>
                {panel ? <Typography className='breadCrumbs' onClick={() => breadcrumbClick(panel)}>{formatPanelTag(panel.get('tag'))}</Typography> : ''}
                {controller ? <Typography className='breadCrumbs'>{controller.get('tag')}</Typography> : ''}
              </Breadcrumbs>
            </div>
            <TreeView
              onNodeSelect={handleChange}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ maxHeight: isMobile ? '300px' : '60vh', flexGrow: 1, maxWidth: isMobile ? 900 : 400, overflowY: 'auto' }}
            >
              {deviceTreeFiltered && deviceTreeFiltered.get('panels')
                ? filterPanels(deviceTreeFiltered.get('panels')).map((panel) => renderTree(panel, deviceTreeFiltered.get('alarmCounts', Map())))
                : null}
            </TreeView>

            <PermissionFence can='edit-device'>
              <div className='actionButtons'>
                <Button variant="contained" cta onClick={event => clickOnNewPanel(event)} icon={<AddIcon style={{ fontSize: 14 }} />}>
                  Add Panel
                </Button>
                <Button onClick={() => setReduxAddControllerModal()} variant="contained" cta className='addNewController' icon={<AddIcon style={{ fontSize: 14 }} />} >
                  Add Controller
                </Button>
              </div>

              <Popover
                open={showOptions}
                anchorEl={anchorEl}
                onClose={() => setShowOptions(false)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuList className='menuList'>
                  <MenuItem onClick={event => clickEdit(event)}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={event => clickDelete(event)}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              </Popover>

              <AddControllerModal />
            </PermissionFence>
          </> : null
      }

    </div>
  );
}


GroupedDevicesMenu.propTypes = {
  setSelectedDevice: PropTypes.func,
  setShowCreateNewPanel: PropTypes.func,
  setSelectedPanel: PropTypes.func,
  onPanelClick: PropTypes.func,
  onControllerClick: PropTypes.func

};

GroupedDevicesMenu.defaultProps = {
  setSelectedDevice: null,
  setShowCreateNewPanel: null,
  setSelectedPanel: null
};
